﻿.homepage-index, .homepage-cancertype {
    .hero {
        position: relative;
        overflow: hidden;
        color: var(--nsw-text-light);
        padding: $spacer*4 0;
        background-color: var(--nsw-brand-dark);

        @include desktop {
            padding: $spacer*8 0;
        }

        h1 {
            margin: 0;
        }

        h2 {
            margin-top: $spacer;
        }

        * {
            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        &.no-sub-title {
            h1 {
                margin-bottom: $spacer*6;

                @include desktop {
                    margin-bottom: $spacer*4;
                }
            }
        }
    }

    .hero-content {
        background-color: $white;
        padding: $spacer*5;
        color: var(--nsw-text-dark);
        border-radius: 4px;
        margin-top: -$spacer*6;

        > * {
            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0
            }
        }

        ul {
            li {
                font-size: 16px;
            }
        }
    }

    .option-tiles-section {
        .card-body {
            i[class*="sprite"] {
                font-size: 14px;
            }
        }
    }
}

.icon-cards {

    .icon-wrapper {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
    }

    .nsw-card {
        .nsw-card__image {
            min-height: 9rem;
            height: 9rem;
        }

        .nsw-card__content {
            padding: 1.5rem 1.5rem 4.5rem 1.5rem;
        }

        .icon-wrapper {
            border: 1px solid var(--nsw-grey-03);
            border-bottom: 0;
            border-top-left-radius: var(--nsw-border-radius);
            border-top-right-radius: var(--nsw-border-radius);
            background-color: var(--nsw-white);

            img {
                width: 100px;
                height: 100px;
            }
        }
    }

    .nsw-card--horizontal {
        .nsw-card__image {
            min-height: 12.5rem;
            height: 12.5rem;
        }

        .icon-wrapper {
            border: 1px solid var(--nsw-grey-03);
            border-right: 0;
            border-top-left-radius: var(--nsw-border-radius);
            border-bottom-left-radius: var(--nsw-border-radius);
            border-top-right-radius: 0;
            background-color: var(--nsw-white);
        }
    }
}



