﻿.account-edit,
.account-login,
.account-register,
.account-retrievepassword {
    .global-cancer-type-menu {
        display: none;
    }
}


.modal#deleteAccountConfirmModal {
    .close {
        margin-top: 13px;

        @include mobile {
            position: relative;
            float: right;
            margin-top: -30px;
        }
    }
}