@import 'utils/_mixins';
@import '_variables';

.alert {
	color: $black;
	background-color: $snow;
	border-left-width: $spacer;
	border-color: $silver;
	margin: $spacer*3 0 $spacer*4;
	padding: $spacer*2 $spacer*3;

	&.alert-success {
		color: $green-dark;
		border-color: $green-light;
		background-color: $green-lighter;
	}

	&.alert-info {
		color: $blue-dark;
		border-color: $blue-light;
		background-color: $blue-lighter;
	}

	&.alert-warning {
		color: $orange-dark;
		border-color: $orange-light;
		background-color: $orange-lighter;
	}

	&.alert-danger {
		color: $red-dark;
		border-color: $red-light;
		background-color: $red-lighter;
	}
}

.alert, .panel, .well {

	>*:first-child {
		margin-top: 0;
	}

	>*:last-child {
		margin-bottom: 0;
	}
}


.popover {

	@include desktop {
		max-width: 400px;
	}

	.popover-title {
		background-color: transparent;
		font-weight: bold;
	}

}