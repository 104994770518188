﻿.search-page {

    .search-header {
        border: 0px;
        padding-top: $spacer * 3;

        @include desktop {
            padding-top: $spacer * 5;
        }

        h1 {

            @include desktop {
                margin-bottom: $card-margin-default;
            }

            @include mobile {
                margin-bottom: $margin-default;
            }
        }
    }

    .search-input {

        .input-group {
            width: 100%;

            .form-control, input, button {
                height: 46.6px !important;
            }
            .input-group-btn {
                width: 1%;
            }
        }
    }

    .search-result-panel {

        .search-message {
            padding: 44px 0px 16px 0px;
            color: #555;

            @include mobile {
                padding: 32px 0px 16px 0px;
            }
        }

        .search-result-list {

            .search-result {
                padding-bottom: $padding-default;

                &:first-child {
                    padding-top: 27px;
                    border-top: 1px solid $submenu-border-bottom-color;

                    @include mobile {
                        padding-top: 24px;
                    }
                }

                &:last-child {
                    padding-bottom: $padding-default;
                    border-bottom: 1px solid $submenu-border-bottom-color;
                }

                .result-item-title {
                    font-size: $font-size-semi-medium;
                    font-weight: bold;
                    line-height: 21px;
                    padding-bottom: $padding-medium / 2;
                }

                .result-item-body {
                    line-height: 24px;
                }
            }
        }
    }

    .pagination-container {
        text-align: center;

        .pagination {

            display: inline-block;
            flex-direction: initial;
            flex-wrap: initial;

            @include desktop {
                margin: 24px 0 88px 0;
            }

            @include mobile {
                margin: 16px 0 72px 0;
            }

            li {

                a {
                    color: $blue;
                    height: 39px;
                    width: 39px;
                    line-height: 24px;
                    text-align: center;
                    padding: 8px 0px;
                    margin-left: 8px;
                    border-radius: 4px;
                    border: 1px solid #ccc;

                    &:focus,
                    &:hover {
                        color: #fff;
                        background-color: $hover-color;
                    }
                }

                &:first-child a {
                    margin-left: 0px;
                }

                &.active a {
                    background-color: $blue;
                    border-color: $blue;
                    color: #fff;
                }

                &.disabled a {
                    color: #ccc;

                    &:focus,
                    &:hover {
                        background-color: inherit;
                    }
                }
            }

            .PagedList-skipToFirst,
            .PagedList-skipToPrevious,
            .PagedList-skipToNext,
            .PagedList-skipToLast {
                font: normal normal normal 14px/1 FontAwesome;
                font-size: inherit;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;

                a {
                    padding-top: 7px;
                    font-size: 0px;

                    &:before {
                        font-size: 22px;
                    }
                }
            }

            .PagedList-skipToFirst a:before {
                padding-right: 3px;
                content: "\f100";
            }

            .PagedList-skipToPrevious a:before {
                padding-right: 3px;
                content: "\f104";
            }

            .PagedList-skipToNext a:before {
                padding-left: 3px;
                content: "\f105";
            }

            .PagedList-skipToLast a:before {
                padding-left: 3px;
                content: "\f101";
            }

            .PagedList-ellipses {

                + li a {
                    margin-left: 4px;
                }

                a {
                    border: 0px;
                    width: 10px;
                    padding: 4px 0;
                    margin-left: 4px;
                }
            }
        }
    }
}
