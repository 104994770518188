﻿.glossary {
    display: flex;

    .glossary-terms {
        div[data-letter] {
            h2 {
                
    
            }

            h3 {

            }

            .glossary-item-container {
                &.glossary-item-container:first-of-type {
                    h3 {
                        margin-top: $spacer*2;
                    }
                }
                p {
                    margin-bottom: $spacer*3;
                }
                &.glossary-item-container:last-of-type {
                    p {
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }

    .glossary-index {
        & > nav {
            margin-right: $spacer*3;
            padding: $spacer;
            border: 1px solid $pi-color-gray-6;

            @include desktop {
                margin-right: $spacer*6;
            }

            .pagination {
                flex-direction: column;
                margin: 0;
                display: flex;


                & > li {
                    a, span {

                        border-radius: 4px;
                        margin: 0 4px 4px 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 40px;
                        height: 40px;
                        margin-right: 0;
                    }

                    &.disabled {
                        a, span {

                        }
                    }
                }
            }
        }
    }

    #btnBackToGlossaryTop {
        font-weight: 400;
        text-decoration: none;
        padding: 10px 0px;
        margin-top: $spacer*8;

        &:hover, &:focus {
            text-decoration: none;
        }
    }
}