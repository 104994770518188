﻿.cards {
    .card {
        .card-gray {
            background-color: $snow;
        }
    }
}

.glossary-listing-container {
    .summary-cards-collapsible {
        .panel-body, .panel-heading {
            background-color: $white;
        }
    }
}

.summary-cards-collapsible {

    .summary-card-toggle-collapse {
        cursor: pointer;
        @extend .pi-icon-angle-down;
        @include transform-rotate(0.5turn);
        position: absolute;
        right: $spacer*2;
        top: 2px;
    }

    .collapsed .summary-card-toggle-collapse {
        @include transform-rotate(1turn);
    }

    .panel-heading, .panel-body {
        background-color: $pi-color-gray-8;
    }
    
    &.bg-gray-8 {
        .panel-heading, .panel-body {
            background-color: $white;
        }
    }

    .panel-heading {

        cursor: pointer;
        padding-top: $spacer*3;
        padding-bottom: $spacer*3;
        border: 1px solid transparent;
        border-radius: 4px;

        &:focus, &:hover {
            outline: inherit;
            box-shadow: inherit;
            border-color: $blue-dark;
        }

        .panel-title {
            font-weight: bold;
        }

        &.collapsed {
            .panel-title {
                font-weight: inherit;
            }

            .summary-card-toggle-collapse {
                top: -4px;
            }
        }
    }

    .panel {
        margin-bottom: $spacer*2 !important;
    }

    .panel-title {
        font-weight: normal;
        font-size: $font-size-semi-medium;
        color: $almost-black;
        line-height: 20px;
    }

    .summary-card-toggle-collapse:before {
        font-size: 8px;
        color: $blue;
    }

    .panel-body {
        padding: $spacer*3 0px $spacer*2 0px;
        margin-top: 2px;
    }

    .big-chevron-right {
        display: block;
        padding: $spacer*2 $spacer*2 1px 1px;
    }

    .big-chevron-text i {
        font-size: 11px;
        padding-bottom: 10px;
        position: absolute;
        margin-top: 7px;
        margin-left: 6px;
    }

    @include mobile() {
        .big-chevron-right {
            display: block;
        }

        .big-chevron-text {
            display: inline;
        }
    }
}