﻿$padding: 14px;

.search-input {
    form {
        margin-bottom: $spacer*3;
    }

    input {
        @include no-appearance;
        padding: $padding;
        box-shadow: none;
        border-right-width: 0px;
        height: auto;
        background-color: #F5F9FC;
        border: 1px solid #D4D7D9;
        height: 39px !important;

        @include ie {
            padding: 0px 0px 0px 16.5px !important;
            outline-color: transparent;
        }
    }

    .input-group .form-control:not(:first-child):not(:last-child) {
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
    }

    button {
        border-color: #979797;
        border-left-width: 0px;
        color: $purple;

        &:hover, &:active, &:focus {
            color: #fff;
            background-color: #F5F9FC;
            border: 1px solid #D4D7D9;
            border: none;
        }
    }

    input,
    button {
        color: #495057;
        font-size: $font-size-btn;
        border-color: #ccc;
    }

    .form-control:focus {
        box-shadow: none;
        -webkit-box-shadow: none;
    }

    .form-control:focus + .input-group-btn button {
        border: 1px solid #D4D7D9;
    }
}
