﻿.nav-tiles {
    text-align: center;
    margin-bottom: 70px;

    @include mobile {
        margin-bottom: 20px;
    }

    .nav-tiles-title {
        h3 {
            font-size: 36px;
            color: $almost-black;
            margin-top: 76px;
            margin-bottom: 60px;

            @include mobile {
                font-size: $font-size-mobile-h3;
                margin-top: 40px;
                margin-bottom: 40px;
            }

            @include tablet {
                font-size: 36px;
                margin-top: 54px;
                margin-bottom: 58px;
                width: 70%;
                margin-left: 15%;
            }
        }
    }

    .nav-tile-link {
        display: block;
        height: 270px;
        min-height: 270px;
        width: 100%;
        background-color: $menu-item-light-grey-background-color;
        border: 1px solid $submenu-border-bottom-color;
        border-radius: 4px;
        margin-bottom: $card-margin-default;

        @include mobile {
            height: calc(100% + 32px);
            min-height: 300px;
        }

        &:hover, &:focus {
            border-color: $dark-blue;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,0.15);

            .nav-tile {
                .nav-tile-title {
                    .nav-tile-title-text {
                        color: $dark-blue;
                    }
                }
            }
        }

        .nav-tile {
            display: table;
            vertical-align: top;

            .nav-tile-thumb {
                display: table-row;

                img {
                    width: 100%;
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                }
            }

            .nav-tile-title {
                display: table-cell;
                height: 100px;
                vertical-align: middle;

                .nav-tile-title-text {
                    display: inline-block;
                    width: 75%;
                    text-align: center;
                    color: $almost-black;
                    font-size: $font-size-medium;
                    font-family: 'Lola Bold', serif;
                }
            }
        }
    }
}