﻿//Global signin register page css
.signin-register {
    margin-bottom: 69px;

    @include mobile {
       
        margin-bottom: 62px;
    }

    .h1-title {
          margin-top: 0px;
        margin-bottom: $spacer*5;

        @include mobile {
            margin-bottom: $spacer*4;
            margin-top: $spacer*3;
        }
    }

    .tandc {
        display: inline-block;

        label {
            display: inline;

            a {
                display: inline-block;
            }
        }
    }

    .terms-container {
        height: 100px;
    }

    #terms {
        background: #f5f5f5;
        padding: 10px;
        font-size: 12px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        border-radius: 10px;
        overflow-y: auto;

        h2 {
            margin-top: 0px;
            margin-bottom: $spacer;
           
        }

        p {
            padding-left: 5px;
            font-size: 12px;
        }

        .terms-container {
            height: 100px;
        }
    }

    a[data-target="#terms"] {
        &:after {
            @extend .pi-icon;
            position: relative;
            top: -1.5px;
            content: $pi-icon-char-angle-down;
            margin-left: 8px;
            font-size: 8px;
            float: right;
            top: 8px;
            @include transform-rotate(.5turn);
        }

        &.collapsed {
            &:after {
                @include transform-rotate(1turn);
            }
        }
    }
}

.signin-register.signin-register-one-header {
    margin-bottom: 360px;

    @include mobile {
        margin-bottom: 104px;
        overflow: hidden;
    }

    .background-circles-container {
        @include mobile {
            display: none;
        }
    }

    h1 {
        margin-top: 40px;
        margin-bottom: 60px;

        @include mobile {
            margin-bottom: 32px;
        }
    }

    h2 {
       
        margin-top: 0;
        margin-bottom: $margin-default;

        @include mobile {
           
            margin-bottom: $margin-medium;
        }
    }

    .signin-panel-1, .signin-panel-2 {
        margin-top: 0;
        margin-bottom: 46px;

        @include mobile {
            margin-bottom: 40px;
        }
    }

    .signin-panel-1 {
        border-right-width: 0px;
    }

    .signin-panel-2 {
        border-left: 1px solid $submenu-border-bottom-color;

        @include mobile {
            border-left-width: 0;
            border-top: 1px solid $submenu-border-bottom-color;
            padding-top: 40px;
        }
    }

    .btn-danger.btn-secondary {
        @include mobile {
            width: 100%;
        }
    }
}

.separator {
    border-top: 1px solid $submenu-border-bottom-color;
    margin-bottom: 24px;

    @include mobile {
        margin-bottom: 30px;
    }
}

.btn-secondary.btn-danger {
    border: 1px solid $red;
    background-color: transparent;
    color: $red;

    &:hover {
        background-color: $red;
        color: #fff;
    }
}

// login page css
.signin-register {

    .register-intro-text {
        margin-bottom: 42px;
        font-size: 20px;
    }
}

.signin-panel-1 {
    border-right: 1px solid $submenu-border-bottom-color;
    padding-right: 64.5px;

    @include mobile {
        border-right-width: 0px;
        padding-right: 15px;
    }
}

.signin-panel-2 {
    padding-left: 64.5px;

    @include mobile {
        padding-left: 15px;
    }
}

.signin-panel-1, .signin-panel-2, .register-panel  {
    margin-top: 60px;

    @include mobile {
        margin-top: 0;
    }

    h1 {
        margin-top: 16px;

        @include mobile {
            margin-top: 32px;
        }
    }
}

@include tablet {
    .signin-panel-2 {
        border-left: none !important;
        padding-left: 15px;
    }
}