﻿@import 'utils/_mixins';
@import '_variables';

.bizform {
    padding: 0px 0px 60px 0px;

    @include mobile {
        padding: 0px 0px 42px 0px;
    }

    .form-header {
        margin-top: 60px;
        margin-bottom: $margin-default;

        h1 {
            margin: 0px;

            @include mobile {
            }
        }
    }

    .form-body {
        padding-bottom: $padding-default;

        p:last-of-type {
            margin-bottom: 0px;
        }
    }

    .form-group {

        label {
            margin-bottom: 8px;

            @include mobile {
                margin-bottom: 7px;
            }
        }

        .form-control {
            &::-webkit-input-placeholder { 
                color: #666 !important;
            }
            &:-moz-placeholder { 
                color: #666 !important;
                opacity:  1;
            }

            &::-moz-placeholder { 
                color: #666 !important;
                opacity:  1;
            }

            &:-ms-input-placeholder { 
                color: #666 !important;
            }

            &::-ms-input-placeholder { 
                color: #666 !important;
            }
            
            &::placeholder { 
                color: #666 !important;
            }
        }

        textarea {
            height: auto;
            min-height: 220px;
            border: 1px solid #ccc;
            padding: 17px 16px;

            @include mobile {
                min-height: 365px;
            }
        }
    }
}