﻿$sticky-box-shadow: 0 4px 2px -2px rgba(0,0,0,.15);
$sticky-box-shadow-white: 0 6px 2px -2px #fff;
.sticky-tabs {
    &.affix {
        background-color: #fff;
        z-index: 2;
        position: fixed;
        top: 87px;
    }
    left: 0;
    width: 100%;
    z-index: 2;
}
.sticky-title {
    &.affix {
        background-color: #fff;
        z-index: 1;
        position: fixed;

        .cancertype-badge {
            display: none;
        }
    }

    margin-top: -$spacer*3;
    padding-top: $spacer*3;
    z-index: 1;
    top: $spacer*3;
    left: 0;
    width: 100%;
}
