$icons: (
	  sprite: (width: 279px, height: 253px, pngPath: '/Content/pinfo/Images/sprite.png', svgPath: '/Content/pinfo/Images/sprite.svg'),
 	icon-advanced: (width: 44px, height: 50px, backgroundX: -85px, backgroundY: -74px),
 	icon-be-prepared: (width: 32px, height: 30px, backgroundX: -225px, backgroundY: -164px),
 	icon-be-prepared-blue: (width: 32px, height: 30px, backgroundX: 0px, backgroundY: -213px),
 	icon-caring: (width: 56px, height: 50px, backgroundX: -155px, backgroundY: -74px),
 	icon-close: (width: 16px, height: 16px, backgroundX: -198px, backgroundY: -139px),
 	icon-diagnosed: (width: 36px, height: 50px, backgroundX: -74px, backgroundY: -139px),
 	icon-finishing: (width: 27px, height: 50px, backgroundX: -120px, backgroundY: -139px),
 	icon-managing-symptoms: (width: 37px, height: 32px, backgroundX: -225px, backgroundY: -80px),
 	icon-managing-symptoms-blue: (width: 37px, height: 32px, backgroundX: -225px, backgroundY: -122px),
 	icon-next-steps: (width: 25px, height: 29px, backgroundX: -124px, backgroundY: -213px),
 	icon-next-steps-blue: (width: 25px, height: 29px, backgroundX: -159px, backgroundY: -213px),
 	icon-support: (width: 75px, height: 50px, backgroundX: 0px, backgroundY: 0px),
 	icon-treatment: (width: 31px, height: 50px, backgroundX: -157px, backgroundY: -139px),
 	icon-what-to-ask: (width: 44px, height: 30px, backgroundX: -225px, backgroundY: 0px),
 	icon-what-to-ask-blue: (width: 44px, height: 30px, backgroundX: -225px, backgroundY: -40px),
 	icon-what-you-need-to-know: (width: 31px, height: 30px, backgroundX: -42px, backgroundY: -213px),
 	icon-what-you-need-to-know-blue: (width: 31px, height: 30px, backgroundX: -83px, backgroundY: -213px),
 	waratah: (width: 60px, height: 64px, backgroundX: -85px, backgroundY: 0px),
 	waratah-nsw: (width: 60px, height: 64px, backgroundX: -155px, backgroundY: 0px),
 	waratah-nsw-reversed: (width: 69px, height: 69px, backgroundX: 0px, backgroundY: -60px),
 	waratah-reversed: (width: 64px, height: 64px, backgroundX: 0px, backgroundY: -139px),
);

$ieSprite: '.lt-ie9' !default;
$sprite: map-get($icons, sprite) !default;
$baseFontSize: 16px !default;

@import "sprites-mixins";

.sprite-icon-advanced {
    display: inline-block;
    width: 44px;
    height: 50px;
    @include sprite(icon-advanced);
}
.sprite-icon-be-prepared {
    display: inline-block;
    width: 32px;
    height: 30px;
    @include sprite(icon-be-prepared);
}
.sprite-icon-be-prepared-blue {
    display: inline-block;
    width: 32px;
    height: 30px;
    @include sprite(icon-be-prepared-blue);
}
.sprite-icon-caring {
    display: inline-block;
    width: 56px;
    height: 50px;
    @include sprite(icon-caring);
}
.sprite-icon-close {
    display: inline-block;
    width: 16px;
    height: 16px;
    @include sprite(icon-close);
}
.sprite-icon-diagnosed {
    display: inline-block;
    width: 36px;
    height: 50px;
    @include sprite(icon-diagnosed);
}
.sprite-icon-finishing {
    display: inline-block;
    width: 27px;
    height: 50px;
    @include sprite(icon-finishing);
}
.sprite-icon-managing-symptoms {
    display: inline-block;
    width: 37px;
    height: 32px;
    @include sprite(icon-managing-symptoms);
}
.sprite-icon-managing-symptoms-blue {
    display: inline-block;
    width: 37px;
    height: 32px;
    @include sprite(icon-managing-symptoms-blue);
}
.sprite-icon-next-steps {
    display: inline-block;
    width: 25px;
    height: 29px;
    @include sprite(icon-next-steps);
}
.sprite-icon-next-steps-blue {
    display: inline-block;
    width: 25px;
    height: 29px;
    @include sprite(icon-next-steps-blue);
}
.sprite-icon-support {
    display: inline-block;
    width: 75px;
    height: 50px;
    @include sprite(icon-support);
}
.sprite-icon-treatment {
    display: inline-block;
    width: 31px;
    height: 50px;
    @include sprite(icon-treatment);
}
.sprite-icon-what-to-ask {
    display: inline-block;
    width: 44px;
    height: 30px;
    @include sprite(icon-what-to-ask);
}
.sprite-icon-what-to-ask-blue {
    display: inline-block;
    width: 44px;
    height: 30px;
    @include sprite(icon-what-to-ask-blue);
}
.sprite-icon-what-you-need-to-know {
    display: inline-block;
    width: 31px;
    height: 30px;
    @include sprite(icon-what-you-need-to-know);
}
.sprite-icon-what-you-need-to-know-blue {
    display: inline-block;
    width: 31px;
    height: 30px;
    @include sprite(icon-what-you-need-to-know-blue);
}
.sprite-waratah {
    display: inline-block;
    width: 60px;
    height: 64px;
    @include sprite(waratah);
}
.sprite-waratah-nsw {
    display: inline-block;
    width: 60px;
    height: 64px;
    @include sprite(waratah-nsw);
}
.sprite-waratah-nsw-reversed {
    display: inline-block;
    width: 69px;
    height: 69px;
    @include sprite(waratah-nsw-reversed);
}
.sprite-waratah-reversed {
    display: inline-block;
    width: 64px;
    height: 64px;
    @include sprite(waratah-reversed);
}
