﻿.on-hover {
    display: none;
}

.tabs li:hover,
.tabs li.active {
    
    

    .on-hover {
        display: inline-block;
    }

    .off-hover {
        display: none;
    }
}

h2.tab-title {
    margin: 0;
    color: #fff;
}

.nav-tabs {
    border-bottom: 0;
}

.tabs {
    text-align: center;
    box-sizing: border-box;
    width: 100%;
    display: table;
    table-layout: fixed;

    li {
        color: var(--nsw-text-dark);
        float: none;
        display: inline-block;
        border-bottom-color: transparent;
        padding: 0;
        display: table-cell;
        width: 100%;

        a {
            border-bottom: 2px solid var(--nsw-brand-dark);
            padding: 5px 5px 10px 5px;
            padding-bottom: 10px;
        }

        .tab-title {
            font-size: 20px !important;
            line-height: 18px;
            color: var(--nsw-text-dark);
            margin: 0px;

            i[class*="sprite"] {
                margin-right: 16px;
                font-size: 14px;
            }
        }
    }

    li.active {
        color: var(--nsw-text-light);
    }

    li.active {
        

        a {
            background-color: var(--nsw-brand-dark);
            color: var( --nsw-text-light);
        }

        .tab-title {
            color: var(--nsw-text-light);
        }

        a.tab {
            background-color: transparent;
        }

        .tab-title:before {
            color: var( --nsw-text-light);
        }
    }

    li a:hover, li a:focus {
        background-color: inherit;
    }
}

@include mobile {
    .multiple-tab-pane .tab-pane {
        display: none !important;
    }
}

div[id$=-accordion] {
    margin-left: -17px;
    margin-right: -17px;

    .content-block-title {
        font-weight: bold;
        font-size: $font-size-medium;
        line-height: 30px;
        margin-bottom: 8px;
    }

    .content-block-title {
        line-height: 24px;
    }

    .tab-title {
        line-height: 30px;
        
        font-size: $font-size-medium;
        font-weight: bold;
    }

    .panel {
        margin-top: 2px !important;
        border: none;
    }

    .panel-heading {
        background-color: $dark-blue;
        color: $font-inverse-color;
        font-size: $font-size-medium;
        padding-top: 11px;
        padding-bottom: 11px;

        a {
            text-decoration: none;
            &:hover, &:focus {
                text-decoration: none;
            }
        }
    }

    .tab-title:before {
        display: none;
    }

    .accordion-toggle-icon:after {
        @extend .pi-icon;
        content: "\e906";
        float: right;
        font-size: 10px;
        margin-top: 10px;
        @include transform-rotate(-180deg);
    }

    .tab.collapsed .accordion-toggle-icon:after,
    .js-tabcollapse-panel-heading.collapsed .accordion-toggle-icon:after {
        @include transform-rotate(0deg);
    }

    .js-tabcollapse-panel-body {
        padding-bottom: 0px;
    }

    @include mobile {
        .panel-collapse.collapse.in {
            display: block !important;
        }
        .panel-collapse.collapse.show.in {
            display: block !important;
        }
        .panel-collapse.show.collapse {
            display: none !important;
        }
    }

    @include tablet {
        .panel-collapse.collapse.in {
            display: block !important;
        }
        .panel-collapse.collapse.show.in {
            display: block !important;
        }
        .panel-collapse.show.collapse {
            display: none !important;
        }
    }
    
}