﻿@import 'utils/_mixins';

.l-right-panel {
    @extend .col-md-offset-1;
    @extend .col-md-4;
}

.l-centered {
    text-align: center;

    .col-md-4, .col-sm-3, .col-md-3 {

        @include desktop {
            float: none;
            display: inline-block;
            margin-right: -4px;
            vertical-align: top;
        }

        @include tablet {
            float: none;
            display: inline-block;
            margin-right: -4px;
            vertical-align: top;
        }
    }
}

#back-to-top {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    padding: 0.8rem;
    z-index: 99;
}

.skip-to-content {
    position: absolute;
    padding: 1em 2em;
    background-color: #54267e;
    color: #fff !important;
    border: 1px solid #54267e;
    font-weight: bold;
    text-align: center;
    padding: 1em;
    top: 0;
    text-decoration: underline;
    z-index: 99993;
    left: 30%;
    transition: transform .166s;
    transform: translate(-50%, -110%);
    opacity: 0;
    pointer-events: none;

    &:focus {
        transform: translate(-50%, 0%);
        opacity: 1;
        background-color: #8C0080 !important;
        text-decoration: none;
    }
}


.related-pages .col-sm-3 {
    text-align: left;
}