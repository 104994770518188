@include desktop {
	.container {
		&.container-narrow {
			width: 780px;
		}
	}
}

.flex-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	&:before, &:after {
		display: none;
	}
}

.row-centered {
	text-align: center;

	[class*="col-"] {
		float: none;
		display: inline-block;
		vertical-align: top;
		margin-right: -4px;
	}
}

.row-bordered {

	display: flex;
	flex-direction: column;
	@include desktop {
		display: block;
	}

	>[class*='col-'] {

		margin-bottom: $spacer*3;

		@include desktop {
			&:nth-child(1) {
				order: 2;
				padding-right: $spacer*8;
				border-right: 1px solid $silver;
			}

			&:nth-child(2) {
				order: 1;
				padding-left: $spacer*8;
			}
		}
	}
}

.row-sm {
	margin-left: -5px;
	margin-right: -5px;

	[class*="col-"] {
		padding-left: 5px;
		padding-right: 5px;
	}
}

@include desktop {
	.row-lg {
		margin-left: -30px;
		margin-right: -30px;

		[class*="col-"] {
			padding-left: 30px;
			padding-right: 30px;
		}
	}
}

