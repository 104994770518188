.navbar {
    background-color: var(--nsw-off-white);
    min-height: auto !important;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 !important;
    border: 0 !important;
    margin-bottom: 0 !important;
    font-weight: 400;
    text-align: left;
    z-index: 1010;

    a {
        text-decoration: none;
        color: var(--nsw-text-dark);
    }

    .navbar-collapse {
        width: 100%;
        z-index: 3;
        position: absolute;
        top: 0;
        transform: translateX(-100%);
        transition: transform .5s;
        display: block !important;

        @include desktop {
            position: relative;
            transform: none;

            .dd-mega-menu-row {
                > div.col-4:nth-child(n+4) {
                    margin-top: 1.5rem;
                }

                .col-4, .col-6 {
                    p {
                        padding-left: 1rem;
                        font-size: $font-size-default;
                        line-height: 110%;
                        margin-bottom: 15px;
                    }
                }
            }

            .navbar-nav > li.hover > a {
                background-color: var(--nsw-text-hover);
                border-bottom: none;
            }
        }

        @include mobiletablet {
            .navbar-nav {
                height: calc(100vh - 200px - env(safe-area-inset-bottom));
                display: flex;
                flex-direction: column;
                padding-left: 0;
                margin-bottom: 0;
                list-style: none;
                overflow-y: scroll;
                overflow-x: hidden;

                li {
                    position: relative;
                }

                .nav-item.active a {
                    //background-color: var(--nsw-off-white);
                }

                .nav-level-two-primary-menu {
                    padding-right: 24px !important;
                    padding-left: 24px !important;
                    //background-color: #ffffff;
                }

                .nav-level-three {
                    background-color: var(--nsw-palette-grey-04);
                    margin: 0 !important;

                    li a {
                        font-size: 13.75px;
                    }

                    .dropdown-item {
                        //border-top: 1px solid $pi-color-gray-6 !important;
                    }
                }
            }

            .oursites {
                //background-color: $primary;
                padding: 32px 16px 0;
                height: calc(200px + env(safe-area-inset-bottom));
                margin-top: auto;

                .show {
                    .site-arrow {
                        transform: rotateX(0);
                    }
                }

                .site-arrow {
                    transform: rotateX(180deg);
                    margin-left: 1em;
                    //color: $primary;

                    @include smallmobile {
                        margin-left: 0.75em;
                    }
                }

                .dropup {

                    &.show .dropdown-toggle {
                        border-top-left-radius: 0;
                        border-top-right-radius: 0;
                    }

                    .dropdown-toggle {
                        text-align: center;
                        border: none;
                        position: relative;
                        z-index: 2;
                        padding: 12px;
                        background: $white;

                        &:after {
                            display: none;
                        }

                        .ci-icon-our-sites {
                            font-weight: 700;
                            //color: $darkgray;

                            @include smallmobile {
                                font-size: 16px;
                            }
                        }
                    }

                    .dropdown-menu.open {
                        width: 100%;
                        padding: 0;
                        margin: 0;
                        border: none;
                        border-bottom: none;
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;

                        li {
                            a {
                                clear: both;
                                color: var(--nsw-text-dark);
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 1.3;
                                text-decoration: none;
                                display: block;
                                padding: 8px 16px;
                                white-space: nowrap;

                                span {
                                    font-size: 16px;
                                    font-weight: 700;
                                }
                            }
                        }
                    }
                }

                .open + .backdrop {
                    position: fixed;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    //background-color: rgba(0,0,0,.5);
                    z-index: 1;
                    display: block;
                }
            }
        }

        &.open {
            transform: translateX(0);
        }

        .navwrap {
            width: 100%;
            //background-color: $white;

            @include desktop {
                background-color: inherit;
            }

            .navbar-nav {

                @include mobiletablet {
                    .sub-arrow {
                        align-items: center;
                        //border-left: 1px solid $whitesmoke;
                        display: flex;
                        height: 3.6rem;
                        padding: 0 $spacer * 3;
                        position: absolute;
                        right: 0;
                        top: -14px;

                        &:before {
                            font-family: 'FontAwesome';
                            font-weight: 700;
                            content: '\f107';
                            color: var(--nsw-text-dark);
                        }
                    }

                    .open {
                        > a > .sub-arrow {
                            transform: scaleY(-1);
                        }
                    }
                }

                .nav-item {

                    .nav-link {
                        display: flex;
                        align-items: center;
                        border-bottom: none;
                        color: var(--nsw-text-dark);
                        padding: 5px;
                        font-weight: 700;
                        font-size: 14.999px !important;


                        @include hoverfocus {
                            background-color: var(--nsw-text-hover);
                        }

                        @include desktop {
                            justify-content: center;
                            border: none;
                            color: var(--nsw-text-dark);
                            height: 56px;
                            line-height: inherit;

                            @include hoverfocus {
                                background-color: var(--nsw-text-hover);
                            }

                            .caret {
                                margin-left: $spacer;
                            }
                        }
                    }

                    @include desktop {
                        &.mega-menu {
                            position: static;
                            flex: auto;

                            .dropdown-menu {
                                margin-left: 0 !important;
                                width: 100% !important;
                                min-width: 100% !important;
                                max-width: 100% !important;
                                color: var(--nsw-text-dark);

                                h6 {
                                    color: var(--nsw-text-dark);
                                }

                                a {
                                    color: var(--nsw-text-dark);

                                    @include hoverfocus {
                                        color: var(--nsw-text-dark);
                                    }
                                }

                                > li:not(.mega-panel) {
                                    display: none;
                                }

                                > li.mega-panel {
                                    padding: $spacer * 4;

                                    .copy-box {
                                        border-right: solid 1px $white;
                                        font-weight: lighter;
                                    }

                                    p + p {
                                        margin-top: auto;
                                    }
                                }
                            }
                        }
                    }

                    &:first-child {
                        flex: initial;
                    }
                }

                .dropdown-menu {
                    //background-color: $snow;
                    min-width: 200px;
                    max-width: inherit !important;
                    margin-top: 0;
                    border: none;
                    border-radius: 0;
                    padding: 0;
                    position: absolute;

                    @include mobiletablet {
                        position: static;
                        float: none !important;
                    }

                    &:before, &:after {
                        border: none;
                    }

                    @include mobiletablet {
                        .mega-panel {
                            display: none;
                        }

                        .show-hide-menu {
                            display: none !important;
                        }

                        .dropdown-item {
                            font-size: 14.968px !important;
                        }

                        h6 {
                            font-size: 14.968px !important;
                        }
                    }

                    .dropdown-item {
                        //border-top: 1px solid $whitesmoke;
                        padding: 8.5px 24px;
                        font-size: 14.968px !important;
                        text-decoration: none;

                        @include hoverfocus {
                            //background-color: $whitesmoke;
                            color: var(--nsw-text-dark);
                        }
                    }

                    @include desktop {
                        background-color: var(--nsw-off-white);

                        .dropdown-item {
                            color: var(--nsw-text-dark);
                            text-decoration: none;

                            @include hoverfocus {
                                //background-color: $primary;
                            }
                        }
                    }
                }
            }
        }
    }

    .navbar-nav:not(.sm-collapsible) {



        .dropdown-item .sub-arrow {
            border: none;
            margin: 0;
            position: initial;

            &:after {
                font-family: 'FontAwesome';
                content: '\f107';
                transform: rotate(270deg);
                position: absolute;
                right: 1rem;
            }
        }
    }

    &.navbar-light {
        background-color: $white;

        .navbar-collapse {
            .navwrap {
                .navbar-nav {
                    .nav-item {
                        .nav-link {
                            @include desktop {
                                color: var(--nsw-text-dark);

                                @include hoverfocus {
                                    color: var(--nsw-text-dark);
                                }
                            }
                        }
                    }

                    .show .nav-link {
                        color: var(--nsw-text-dark);
                    }
                }
            }
        }
    }

    .row {
        display: flex;
        flex-wrap: wrap;

        &:before {
            content: none;
            display: block;
        }
    }

    h6 {
        font-weight: 700;
        font-size: 14.968px !important;
        letter-spacing: .5px;
        text-transform: uppercase;
    }

    .col-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
        position: relative;
        width: 100%;
    }

    .col-3 {
        flex: 0 0 25%;
        max-width: 25%;
        position: relative;
        width: 100%;
    }

    .col-9 {
        flex: 0 0 75%;
        max-width: 75%;
        position: relative;
        width: 100%;
    }
}

@include desktop {
    .navbar-expand-lg {
        flex-flow: row nowrap;
        justify-content: flex-start;

        #menu-home {
            width: 44px;
        }

        .container {
            padding-left: 5px !important;
            padding-right: 0px !important;
        }

        .navbar-nav {
            flex-direction: row;
            display: flex;
            padding-left: 0;
            margin-bottom: 0;
            list-style: none;
            float: none;
            margin: 0 !important;
        }
    }
}

@include mobiletablet {
    main, .navbar {
        min-height: 0 !important
    }

    .mobile-nav .navbar-collapse {
        padding: 0 !important;
        margin: 0 !important;
        border-top: 0 !important;
        box-shadow: none !important
    }

    .search-result-box {
        padding: 1em
    }

    .search-result-box .search-results__details {
        width: 100%
    }

    .search-result-box img.search-results__icon {
        float: none;
        width: 100%
    }
}

.mobile-nav {
    .navwrap {
        min-height: 100vh;
    }
}

/* mobile and tablet menu */
@include mobiletablet {
    nav.navbar {
        //background-color: #fff;
        border: none;
        border-radius: 0;
        width: 100%;
        min-height: auto;
        z-index: 2;

        .container {
            width: 100%;
            padding-left: 0px;

            .navbar-collapse {
                border: none;
                padding: 0;
                margin: 0;
                margin-top: -50px;
                position: absolute;
                width: 100%;
                transform: translateX(-100%);
                transition: transform .5s;
                z-index: 1;
                // hide tabbable items when menu is closed
                a, button {
                    visibility: hidden;
                }

                &.open {
                    transform: translateX(0);
                    overflow: inherit;
                    // show tabbable items when menu is closed
                    a, button {
                        visibility: visible;
                    }
                }

                .navwrap {
                    //background-color: $primary;
                    //min-height: calc(100vh - 75px);
                    //display: flex;
                    //flex-direction: column;
                    //position: relative;
                    //justify-content: space-between;
                    //flex: initial;

                    .navbar-nav {
                        //background-color: #fff;
                        margin: 0;

                        > li {

                            > a {
                                font-size: 16px;
                                //color: $darkgray;
                                text-decoration: none;

                                &:hover, &:focus {
                                   // background-color: $whitesmoke;
                                }

                                &.highlighted {
                                    font-weight: bold;
                                }
                            }

                            &.active {
                                > a {
                                    font-weight: bold;
                                }
                            }

                            &:first-child > a {
                                border-top: none;
                            }

                            &.open .dropdown-menu {
                                padding: 0 !important; // prevents jump on collapse
                                font-size: $font-size-default;

                                li {
                                    padding: 0 !important; // prevents jump on collapse
                                    a {
                                        

                                        &:hover, &:focus {
                                            //background-color: $whitesmoke;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    /* navbar toggle - shown on smaller screens  */
    .navbar-toggle {
        border-color: transparent;

        .icon-bar {
            //background-color: $white;
            transition: all 0.2s;
        }

        &:hover, &:focus {
            background-color: transparent;

            .icon-bar {
                //background-color: $white;
            }
        }

        &.open .top-bar {
            transform: rotate(45deg);
            transform-origin: 10% 10%;
        }

        &.open .middle-bar {
            opacity: 0;
        }

        &.open .bottom-bar {
            transform: rotate(-45deg);
            transform-origin: 10% 90%;
        }
    }
    /* "our sites" dropdown - shown on smaller screens */
    .oursites {
        width: 100%;
        padding: $spacer*5 $spacer*2;
        height: $spacer*15;
        margin-top: auto;

        .btn {
            display: flex;
            justify-content: space-between;

            &:hover, &:focus {
                //background-color: $snow;
            }

            .fa, .caret {
                //color: $purple;
            }
        }

        #dropdownMenu1 {
            display: block;
            text-align: center;
        }

        .dropdown-menu > li > a {
            padding: $spacer $spacer*2;
            font-size: $font-size-default;
            line-height: 1.3;
            text-decoration: none;
        }

        .open {

            .dropdown-menu {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                margin-bottom: 0;
                -webkit-box-shadow: none;
                box-shadow: none;
                border: none;
                width: 100%;
            }

            .btn {
                //background-color: $white;
                position: relative;
                z-index: 2;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                border: none;

                &:hover, &:focus {
                    //background-color: $snow;
                }

                .caret {
                    transform: rotate(180deg);
                }
            }

            + .backdrop {
                position: fixed;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                //background-color: rgba(0,0,0,.5);
                display: none;
                z-index: 1;
                display: block;
            }
        }
    }
}

/* carets */
.caret {
    margin-left: $spacer;
    border: none !important;
    position: relative;
    width: 12px;
    height: 18px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.caret:before, .navbar-nav.sm-collapsible .caret:before, .navbar-nav.sm-collapsible .open > a > .caret:before {
    font-family: "FontAwesome";
    content: "\f107";
    font-size: 1.33333333em;
    -webkit-transition: -webkit-transform .5s;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
}

.open > a > .caret, .btn-group.open .caret {
    transform: rotate(180deg);
}

.navbar-nav.sm-collapsible .caret, .navbar-nav.sm-collapsible ul .caret {
    margin: 0;
    top: 0;
    width: 60px;
    height: 54px;
    line-height: 54px;
    //border-left: 1px solid $whitesmoke !important;
    border-radius: 0;
    //color: $purple;

    &:hover, &:focus {
        background-color: transparent;
    }
}

.navbar-nav.sm-collapsible .open .dropdown-menu > li > a .caret,
.navbar-nav.sm-collapsible .open .dropdown-menu > li > a .dropdown-menu {
    display: none;
}
