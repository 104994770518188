.breadcrumb-nav {
	padding: 0;
	background-color: $white;
	border-bottom: 1px solid $silver;

	.breadcrumb {
		font-weight: 400 !important;
		line-height: 1.25 !important;
		padding: 0;
		background-color: transparent;
		margin-bottom: 0;
		text-transform: uppercase;



		> li {
			display: none;
			white-space: nowrap;
			font-size: 14px !important;
			font-weight: 400 !important;
			line-height: 1.25 !important;

			a {
				padding: $spacer*1.5 0;
				display: block;
				text-decoration: none;
				font-size: 14px !important;
				font-weight: 400 !important;
				line-height: 1.25 !important;
				color: #54267e !important;
			}

			&.active {
				color: $dimgray;
			}

			&:nth-last-child(2) {
				display: inline-block;

				a:before {
					font-family: 'FontAwesome';
					content: '\f104';
					color: $blue;
					padding: 0 5px;
				}
			}
		}

		> li > a:after {
			content: '';
		}

		> li + li:before {
			content: '';
			padding: 0;
		}

		@include desktop {

			max-width: 750px;

			> li {
				display: inline-block;

				&:nth-last-child(2) a:before {
					display: none;
				}

				> a:after {
					font-family: 'FontAwesome';
					content: '\f105';
					padding: 0 8px 0 8px !important;
					color: #54267e !important;
					font-weight: 900;
				}
			}
		}
	}

	.dropdown {

		> a {
			font-size: 14px !important;
			font-weight: 400 !important;
			line-height: 1.25 !important;
			color: #54267e !important;
			text-transform: uppercase;
			text-align: right;
			background-color: transparent;
			padding: 0 8px 0 8px !important;
			display: flex;
			align-items: center;

			@include hoverfocus {
				background-color: transparent;
			}

			.caret {
				margin-left: $spacer/2;
			}
		}
	}
}


@media only screen and (max-width: 520px) {
	.breadcrumb-nav .breadcrumb {
		display: inline-table;
		margin-right: 5px;
	}

	.breadcrumb-nav .dropdown > a {
		font-size: 12px !important;
	}

	.breadcrumb-nav .breadcrumb > li a {
		font-size: 12px !important;
	}
}