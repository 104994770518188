﻿.section-intro {
    position: relative;
    background-color: $snow;
    border-bottom: 1px solid $silver;
    margin: 0;
    z-index: 1;

    h1 {
        display: block;
        align-items: baseline;
        margin-bottom: $spacer*2;
        margin-top: 0px;

        @include desktop {
            font-size: 40px;
            margin-bottom: $spacer*3;
        }

        .bookmark-button {
            margin-left: 16px;
            position: relative;
            bottom: 4px;
            text-decoration: none;
            white-space: nowrap;
        }
    }

    .background-circles-container {
        display: none;

        .background-circle1 {
            border-color: var(--nsw-brand-dark);
            opacity: .05;
            border-width: 15px;
            right: -100px;
            bottom: 100px;
            width: 400px;
            height: 400px;
        }

        .background-circle2 {
            border-color: var(--nsw-brand-dark);
            opacity: .1;
            border-width: 65px;
            height: 600px;
            width: 600px;
            bottom: -350px;
            right: -100px;
        }

        @include desktop {
            display: block;
        }
    }
}
