﻿.examples {
    font-size: 18px;
    color: #2b2b2b;
    background-color: var(--nsw-palette-grey-04);
    padding: 24px 28px 24px 48px;
    border-radius: 0px;
    border-left: 6px solid var(--nsw-brand-dark);
    font-weight: normal;
    line-height: 24px;
    margin-bottom: 24px;
    margin-top: 24px;

    @include mobile {
        font-size: 16px;
        padding: 16px 16px 16px 48px;
    }
}

.important-information {
    @extend .examples;

    &:before {
        @extend .pi-icon;
        content: $pi-icon-char-important-information;
        display: inline-block;
        position: absolute;
        left: 50px;
        margin-right: $spacer;
        font-size: $spacer*3;

        @include desktop {
            left : 40px;
        }
    }

    ul {
        margin-left: -24px;
    }
}
