﻿.error-page {
    text-align: center;

    @include mobile {
        text-align: left;
    }

    .error-header {
        padding-top: 53px;

        @include mobile {
            padding-top: $padding-default;
        }

        h1 {
            margin: 0px;

        }
    }

    .error-body {
        padding-top: 30px;

        @include mobile {
            padding-top: 28px;
        }
    }

    .error-button {       
        margin-top: 60px;
        margin-bottom: 60px;
        padding: 0px;

        @include mobile {
            margin-top: 32px;
            padding: 0px 15px;
        }

        .btn {
            border-radius: 8px;
            width: 100%;
        }
    }
}