﻿// this is parsed after boostrap import
// use this to override existing generic bootstrap styles

//@import '_bootstrap-variable-overrides';

// Buttons

// Forms

.form-check-label {
    font-weight: normal;
}

.form-control {
    box-shadow: none;
    -webkit-box-shadow: none;
}

label {
    font-weight: 600;
}

// Panels

.panel-title {
    font-weight: bold;
    font-size: 20px;
}

.panel-heading {
    padding-top: 20px;
    padding-bottom: 15px;
}


.text-danger {
    @include mobile {
        font-size: $messagebox-font-size-mobile;
    }
}

.alert-primary {
    border-color: #00ABE6;
    background-color: #AFEAFF;
    color: #005AAD;
    border-left: 5px solid #00ABE6;
}

.alert-danger {
    border-color: #dc0451;
    background-color: #f2dede;
    color: #dc0451;
    border-left: 5px solid #dc0451;
}


.alert {
    border-left-width: 5px;
}

// tables

table, .table {
    width: 100%;
    max-width: 100%;
    margin-top: $spacer*3;
    margin-bottom: $spacer*3;

    @include desktop {
        margin-top: $spacer*5;
        margin-bottom: $spacer*5;
    }
  
      >thead>tr>th {
          border-top: 1px solid $pi-color-gray-6;
          border-bottom-width: 1px;
      }
  
      >thead, >tbody, >tfoot {
          >tr>th, >tr>td {
              line-height: 1.5;
              padding: $spacer*1.5 $spacer*2;
              border-top: 1px solid $pi-color-gray-6;
          }
      }
  
      > caption + thead > tr:first-child > th,
      > caption + thead> tr:first-child > td,
      > colgroup + thead > tr:first-child > th,
      > colgroup + thead > tr:first-child > td,
      > thead:first-child > tr:first-child > th,
      > thead:first-child > tr:first-child > td {
          border-top: 1px solid $pi-color-gray-6;
      }
  
      &.table-striped > tbody > tr:nth-of-type(odd) {
          background-color: $menu-item-light-grey-background-color;
      }
  
    @for $i from 2 through $table-columns {
      &.col#{$i} th, &.col#{$i} td { width: (100% / $i); };
    };
  }
  

// popup modal 

.modal {

    .modal-title {
        float: left;
    }
    
    .modal-content {
        .close {
            opacity: 1;
            color: var(--nsw-brand-dark);
            font-size: 16px;
            margin-top: 3px;
        
            &:after {
                @extend .pi-icon;
                content: "\e90a";
            }
        
            &:hover, &:focus {
                color: var(--nsw-text-hover);
            }
        
            @include mobile {
                float: none;
                width: 100%;
                text-align: right;
            }
        }
    }
}
