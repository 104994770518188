﻿$icon-top: 26px;
$message-content-margin-left: 28px;

.notification-message {
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
    border-right: 0px;
    border-radius: 4px 0px 0px 4px;
    width: 390px;
    padding: 20px 22px 14px 24px;

    @include mobile {
        width: inherit;
        padding-right: 24px;
    }

    .message-title-panel {
        margin-bottom: 8px;

        .icon {
            color: $blue;

            &.disclaimer-icon:before {
                font-family: "Material Icons";
                content: "\e001";
                color: var(--nsw-brand-dark);
                font-size: 24px;
                position: absolute;
            }

            &.bookmark-icon:before {
                font-family: "Material Icons";
                content: "\e866";
                color: var(--nsw-brand-dark);
                font-size: 24px;
                position: absolute;
            }

            &.user-icon:before {
                font-size: 24px;
                position: absolute;
                font-family: "Material Icons";
                content: "\e7fd";
                color: var(--nsw-brand-dark);
            }
        }

        .message-title {
            font-size: $font-size-medium;
            margin-left: $message-content-margin-left;
        }
    }

    .message-body {
        margin-left: $message-content-margin-left;

        .link {
            text-decoration: underline;
            color: var(--nsw-brand-dark);
            font-weight: var(--nsw-font-bold);
        }
    }
}