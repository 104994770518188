﻿@import 'utils/_mixins';
@import '_variables';

.list-sidebar {
	list-style: none;
	padding-left: $spacer*3;
	border-left: 1px solid $blue;
	margin-bottom: $spacer*4;

	li {

		a {
			color: $dimgray;
			text-decoration: none;

			&:hover, &:focus {
				color: $blue-dark;
			}
		}

		ul {
			list-style: none;
			padding-left: $spacer*3;
			margin-top: $spacer;
			margin-bottom: $spacer*3;
		}

		&.active {

			a {
				color: $blue;

				&:hover, &:focus {
					color: $blue-dark;
				}
			}

			>a {
				font-weight: bold;
			}
		}
	}

	li+li {
		margin-top: $spacer;
	}
}

.list-lined {
	list-style: none;
	padding-left: 0;
	margin-bottom: $spacer*4;
	width: 100%;

	li {
		padding: $spacer*1.5 0;
		border-top: 1px solid $silver;
		display: flex;
		justify-content: space-between;
		align-items: center;
		text-align: left;

		a {
			font-size: $font-size-large;
			font-weight: bold;
			text-decoration: none;
			display: block;

			&:hover, &:focus {
				color: $blue-dark;
			}
		}

		>* {
			flex: initial;
			margin-left: $spacer*2;
		}

		>*:first-child {
			flex: auto;
			margin-left: 0;
		}

		&:first-child {
			border-top: none;
		}
	}
}


.pagination {
	margin: $spacer*3 0;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	>li>a, >li>span {
		border-color: $blue;
		border-radius: $spacer/2;
		margin: 0 $spacer/2 $spacer/2 0;
		display: flex;
		justify-content: center;
		align-items: center;
		width: $spacer*5;
		height: $spacer*5;
	}
	>li>a {
		color: $blue;
		&:hover, &:focus {
			color: $white;
			background-color: $blue;
			border-color: $blue;
		}
	}
	>li>span {
		color: $gray;
		border-color: $gray;
		&:hover {
			color: $gray;
			background-color: $white;
			border-color: $gray;
		}
	}
	>li.active>a, >li.active>a:hover, >li.active>a:focus {
		
	}
}

.glossary {
	display: flex;

	.glossary-index>nav {
		margin-right: $spacer*3;
		padding: $spacer;
		background-color: $snow;
		border: 1px solid $silver;

		@include desktop {
			margin-right: $spacer*6;
		}

		.pagination {
			flex-direction: column;
			margin: 0;

			>li>a, >li>span {
				margin-right: 0;
			}
		}
	}
	.glossary-terms {

		>*:first-child {
			margin-top: 0;
		}
		>*:last-child {
			margin-bottom: 0;
		}

		h3 {
			margin-bottom: $spacer/2;
		}
	}
}