@font-face {
    font-family: 'Lola Bold';
    src: url("#{$fonts-path}/fs_lola-bold-webfont.eot");
    src: url("#{$fonts-path}/fs_lola-bold-webfont.eot?#iefix") format("embedded-opentype"), url("#{$fonts-path}/fs_lola-bold-webfont.woff") format("woff"), url("#{$fonts-path}/fs_lola-bold-webfont.ttf") format("truetype"), url("#{$fonts-path}/fs_lola-bold-webfont.svg#fs_lolaregular") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lola Regular';
    src: url("#{$fonts-path}/fs_lola-regular-webfont.eot");
    src: url("#{$fonts-path}/fs_lola-regular-webfont.eot?#iefix") format("embedded-opentype"), url("#{$fonts-path}/fs_lola-regular-webfont.woff") format("woff"), url("#{$fonts-path}/fs_lola-regular-webfont.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Lola Medium";
    src: url("#{$fonts-path}/FSLolaMedium.eot");
    src: url("#{$fonts-path}/FSLolaMedium.eot?#iefix") format("embedded-opentype"), url("#{$fonts-path}/FSLolaMedium.svg#FSLolaMedium") format("svg"), url("#{$fonts-path}/FSLolaMedium.woff") format("woff"), url("#{$fonts-path}/FSLolaMedium.woff2") format("woff2"), url("#{$fonts-path}/FSLolaMedium.ttf") format("truetype");
    font-style: normal
}

@font-face {
    font-family: "Lola Light";
    src: url("#{$fonts-path}/FSLolaLight.eot");
    src: url("#{$fonts-path}/FSLolaLight.eot?#iefix") format("embedded-opentype"), url("#{$fonts-path}/FSLolaLight.svg#FSLolaLight") format("svg"), url("#{$fonts-path}/FSLolaLight.woff") format("woff"), url("#{$fonts-path}/FSLolaLight.woff2") format("woff2"), url("#{$fonts-path}/FSLolaLight.ttf") format("truetype");
    font-style: normal
}

@font-face {
    font-family: 'patientinfo-icon-set';
    src: url("#{$fonts-path}/patientinfo-icon-set.eot?kmlpus");
    src: url("#{$fonts-path}/patientinfo-icon-set.eot?kmlpus#iefix") format("embedded-opentype"), url("#{$fonts-path}/patientinfo-icon-set.ttf?kmlpus") format("truetype"), url("#{$fonts-path}/patientinfo-icon-set.woff?kmlpus") format("woff"), url("#{$fonts-path}/patientinfo-icon-set.svg?kmlpus#patientinfo-icon-set") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FontAwesome';
    src: url('#{$fonts-path}/fontawesome-webfont.eot?v=4.6.2');
    src: url('#{$fonts-path}/fontawesome-webfont.eot?#iefix&v=4.6.2') format('embedded-opentype'),url('#{$fonts-path}/fontawesome-webfont.woff2?v=4.6.2') format('woff2'),url('#{$fonts-path}/fontawesome-webfont.woff?v=4.6.2') format('woff'),url('#{$fonts-path}/fontawesome-webfont.ttf?v=4.6.2') format('truetype'),url('#{$fonts-path}/fontawesome-webfont.svg?v=4.6.2#fontawesomeregular') format('svg');
    font-weight: normal;
    font-style: normal
}

/* cyrillic-ext */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: local('Montserrat Regular'), local('Montserrat-Regular'), url("#{$fonts-path}/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2") format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: local('Montserrat Regular'), local('Montserrat-Regular'), url("#{$fonts-path}/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2") format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: local('Montserrat Regular'), local('Montserrat-Regular'), url("#{$fonts-path}/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2") format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: local('Montserrat Regular'), local('Montserrat-Regular'), url("#{$fonts-path}/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2") format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: local('Montserrat Regular'), local('Montserrat-Regular'), url("#{$fonts-path}/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2") format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url("#{$fonts-path}/JTURjIg1_i6t8kCHKm45_bZF3gTD_u50.woff2") format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url("#{$fonts-path}/JTURjIg1_i6t8kCHKm45_bZF3g3D_u50.woff2") format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url("#{$fonts-path}/JTURjIg1_i6t8kCHKm45_bZF3gbD_u50.woff2") format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url("#{$fonts-path}/JTURjIg1_i6t8kCHKm45_bZF3gfD_u50.woff2") format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url("#{$fonts-path}/JTURjIg1_i6t8kCHKm45_bZF3gnD_g.woff2") format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,400;0,700;1,400&amp;display=swap */

/* vietnamese */
@font-face {
    font-family: 'Public Sans';
    font-style: italic;
    font-weight: 400;
    src: url("#{$fonts-path}/ijwAs572Xtc6ZYQws9YVwnNDZpDyNjGolS673tpRgDcld1sdfg.woff2") format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Public Sans';
    font-style: italic;
    font-weight: 400;
    src: url("#{$fonts-path}/ijwAs572Xtc6ZYQws9YVwnNDZpDyNjGolS673tpRgDckd1sdfg.woff2") format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Public Sans';
    font-style: italic;
    font-weight: 400;
    src: url("#{$fonts-path}/ijwAs572Xtc6ZYQws9YVwnNDZpDyNjGolS673tpRgDcqd1s.woff2") format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 400;
    src: url("#{$fonts-path}/ijwRs572Xtc6ZYQws9YVwnNJfJ7Cww.woff2") format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 400;
    src: url("#{$fonts-path}/ijwRs572Xtc6ZYQws9YVwnNIfJ7Cww.woff2") format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 400;
    src: url("#{$fonts-path}/ijwRs572Xtc6ZYQws9YVwnNGfJ4.woff2") format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 700;
    src: url("#{$fonts-path}/ijwRs572Xtc6ZYQws9YVwnNJfJ7Cww.woff2") format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 700;
    src: url("#{$fonts-path}/ijwRs572Xtc6ZYQws9YVwnNIfJ7Cww.woff2") format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 700;
    src: url("#{$fonts-path}/ijwRs572Xtc6ZYQws9YVwnNGfJ4.woff2") format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* https://fonts.googleapis.com/icon?family=Material+Icons */

/* fallback */
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url("#{$fonts-path}/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2") format('woff2');
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

/* https://fonts.googleapis.com/css?family=Roboto:400,700,300 */

/* cyrillic-ext */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url("#{$fonts-path}/KFOlCnqEu92Fr1MmSU5fCRc4EsA.woff2") format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url("#{$fonts-path}/KFOlCnqEu92Fr1MmSU5fABc4EsA.woff2") format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url("#{$fonts-path}/KFOlCnqEu92Fr1MmSU5fCBc4EsA.woff2") format('woff2');
    unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url("#{$fonts-path}/KFOlCnqEu92Fr1MmSU5fBxc4EsA.woff2") format('woff2');
    unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url("#{$fonts-path}/KFOlCnqEu92Fr1MmSU5fCxc4EsA.woff2") format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url("#{$fonts-path}/KFOlCnqEu92Fr1MmSU5fChc4EsA.woff2") format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url("#{$fonts-path}/KFOlCnqEu92Fr1MmSU5fBBc4.woff2") format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url("#{$fonts-path}/KFOmCnqEu92Fr1Mu72xKOzY.woff2") format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url("#{$fonts-path}/KFOmCnqEu92Fr1Mu5mxKOzY.woff2") format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url("#{$fonts-path}/KFOmCnqEu92Fr1Mu7mxKOzY.woff2") format('woff2');
    unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url("#{$fonts-path}/KFOmCnqEu92Fr1Mu4WxKOzY.woff2") format('woff2');
    unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url("#{$fonts-path}/KFOmCnqEu92Fr1Mu7WxKOzY.woff2") format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url("#{$fonts-path}/KFOmCnqEu92Fr1Mu7GxKOzY.woff2") format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url("#{$fonts-path}/KFOmCnqEu92Fr1Mu4mxK.woff2") format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url("#{$fonts-path}/KFOlCnqEu92Fr1MmWUlfCRc4EsA.woff2") format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url("#{$fonts-path}/KFOlCnqEu92Fr1MmWUlfABc4EsA.woff2") format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url("#{$fonts-path}/KFOlCnqEu92Fr1MmWUlfCBc4EsA.woff2") format('woff2');
    unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url("#{$fonts-path}/KFOlCnqEu92Fr1MmWUlfBxc4EsA.woff2") format('woff2');
    unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url("#{$fonts-path}/KFOlCnqEu92Fr1MmWUlfCxc4EsA.woff2") format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url("#{$fonts-path}/KFOlCnqEu92Fr1MmWUlfChc4EsA.woff2") format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url("#{$fonts-path}/KFOlCnqEu92Fr1MmWUlfBBc4.woff2") format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* https://fonts.googleapis.com/css2?family=Noto+Sans+Syriac:wght@100..900&display=swap */

/* syriac */
@font-face {
    font-family: 'Noto Sans Syriac';
    font-style: normal;
    font-weight: 100 900;
    font-display: swap;
    src: url("#{$fonts-path}/Ktk2AKuMeZjqPnXgyqribqzQqgW0B_e9WLZB.woff2") format('woff2');
    unicode-range: U+0303-0304, U+0307-0308, U+030A, U+0320, U+0323-0325, U+032D-032E, U+0330-0331, U+060C, U+061B-061C, U+061F, U+0621, U+0640, U+064B-0655, U+0660-066C, U+0670, U+0700-074F, U+0860-086A, U+1DF8, U+1DFA, U+200C-200F, U+25CC, U+2670-2671;
}
/* latin-ext */
@font-face {
    font-family: 'Noto Sans Syriac';
    font-style: normal;
    font-weight: 100 900;
    font-display: swap;
    src: url("#{$fonts-path}/Ktk2AKuMeZjqPnXgyqribqzQqgW0B4q9WLZB.woff2") format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Noto Sans Syriac';
    font-style: normal;
    font-weight: 100 900;
    font-display: swap;
    src: url("#{$fonts-path}/Ktk2AKuMeZjqPnXgyqribqzQqgW0B4S9WA.woff2") format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* https://fonts.googleapis.com/css2?family=Amiri:ital,wght@0,400;0,700;1,400;1,700&display=swap */

/* arabic */
@font-face {
    font-family: 'Amiri';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url("#{$fonts-path}/J7afnpd8CGxBHpUrhLQY66NL.woff2") format('woff2');
    unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC, U+102E0-102FB, U+10E60-10E7E, U+10EFD-10EFF, U+1EE00-1EE03, U+1EE05-1EE1F, U+1EE21-1EE22, U+1EE24, U+1EE27, U+1EE29-1EE32, U+1EE34-1EE37, U+1EE39, U+1EE3B, U+1EE42, U+1EE47, U+1EE49, U+1EE4B, U+1EE4D-1EE4F, U+1EE51-1EE52, U+1EE54, U+1EE57, U+1EE59, U+1EE5B, U+1EE5D, U+1EE5F, U+1EE61-1EE62, U+1EE64, U+1EE67-1EE6A, U+1EE6C-1EE72, U+1EE74-1EE77, U+1EE79-1EE7C, U+1EE7E, U+1EE80-1EE89, U+1EE8B-1EE9B, U+1EEA1-1EEA3, U+1EEA5-1EEA9, U+1EEAB-1EEBB, U+1EEF0-1EEF1;
}
/* latin-ext */
@font-face {
    font-family: 'Amiri';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url("#{$fonts-path}/J7afnpd8CGxBHpUrhL8Y66NL.woff2") format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Amiri';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url("#{$fonts-path}/J7afnpd8CGxBHpUrhLEY6w.woff2") format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
    font-family: 'Amiri';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url("#{$fonts-path}/J7aanpd8CGxBHpUrjAo9_plqHwAa.woff2") format('woff2');
    unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC, U+102E0-102FB, U+10E60-10E7E, U+10EFD-10EFF, U+1EE00-1EE03, U+1EE05-1EE1F, U+1EE21-1EE22, U+1EE24, U+1EE27, U+1EE29-1EE32, U+1EE34-1EE37, U+1EE39, U+1EE3B, U+1EE42, U+1EE47, U+1EE49, U+1EE4B, U+1EE4D-1EE4F, U+1EE51-1EE52, U+1EE54, U+1EE57, U+1EE59, U+1EE5B, U+1EE5D, U+1EE5F, U+1EE61-1EE62, U+1EE64, U+1EE67-1EE6A, U+1EE6C-1EE72, U+1EE74-1EE77, U+1EE79-1EE7C, U+1EE7E, U+1EE80-1EE89, U+1EE8B-1EE9B, U+1EEA1-1EEA3, U+1EEA5-1EEA9, U+1EEAB-1EEBB, U+1EEF0-1EEF1;
}
/* latin-ext */
@font-face {
    font-family: 'Amiri';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url("#{$fonts-path}/J7aanpd8CGxBHpUrjAo9_pJqHwAa.woff2") format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Amiri';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url("#{$fonts-path}/J7aanpd8CGxBHpUrjAo9_pxqHw.woff2") format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
    font-family: 'Amiri';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("#{$fonts-path}/J7aRnpd8CGxBHpUrtLMA7w.woff2") format('woff2');
    unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC, U+102E0-102FB, U+10E60-10E7E, U+10EFD-10EFF, U+1EE00-1EE03, U+1EE05-1EE1F, U+1EE21-1EE22, U+1EE24, U+1EE27, U+1EE29-1EE32, U+1EE34-1EE37, U+1EE39, U+1EE3B, U+1EE42, U+1EE47, U+1EE49, U+1EE4B, U+1EE4D-1EE4F, U+1EE51-1EE52, U+1EE54, U+1EE57, U+1EE59, U+1EE5B, U+1EE5D, U+1EE5F, U+1EE61-1EE62, U+1EE64, U+1EE67-1EE6A, U+1EE6C-1EE72, U+1EE74-1EE77, U+1EE79-1EE7C, U+1EE7E, U+1EE80-1EE89, U+1EE8B-1EE9B, U+1EEA1-1EEA3, U+1EEA5-1EEA9, U+1EEAB-1EEBB, U+1EEF0-1EEF1;
}
/* latin-ext */
@font-face {
    font-family: 'Amiri';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("#{$fonts-path}/J7aRnpd8CGxBHpUgtLMA7w.woff2") format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Amiri';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("#{$fonts-path}/J7aRnpd8CGxBHpUutLM.woff2") format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
    font-family: 'Amiri';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("#{$fonts-path}/J7acnpd8CGxBHp2VkaY6zp5yGw.woff2") format('woff2');
    unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC, U+102E0-102FB, U+10E60-10E7E, U+10EFD-10EFF, U+1EE00-1EE03, U+1EE05-1EE1F, U+1EE21-1EE22, U+1EE24, U+1EE27, U+1EE29-1EE32, U+1EE34-1EE37, U+1EE39, U+1EE3B, U+1EE42, U+1EE47, U+1EE49, U+1EE4B, U+1EE4D-1EE4F, U+1EE51-1EE52, U+1EE54, U+1EE57, U+1EE59, U+1EE5B, U+1EE5D, U+1EE5F, U+1EE61-1EE62, U+1EE64, U+1EE67-1EE6A, U+1EE6C-1EE72, U+1EE74-1EE77, U+1EE79-1EE7C, U+1EE7E, U+1EE80-1EE89, U+1EE8B-1EE9B, U+1EEA1-1EEA3, U+1EEA5-1EEA9, U+1EEAB-1EEBB, U+1EEF0-1EEF1;
}
/* latin-ext */
@font-face {
    font-family: 'Amiri';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("#{$fonts-path}/J7acnpd8CGxBHp2VkaYxzp5yGw.woff2") format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Amiri';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("#{$fonts-path}/J7acnpd8CGxBHp2VkaY_zp4.woff2") format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}