﻿.bookmark-button {
    text-decoration: none !important;
    margin: 0px;
    font-size: 20px;
    cursor: pointer;
    
}

.bookmark-button-delete {
    text-decoration: none !important;
    margin: 10px;
    font-size: 24px;
    cursor: pointer;
    color: $pi-color-light-disable;

    &:hover:before, &:focus:before {
        color: $hover-color;
    }

    &:before {
        @extend .pi-icon;
        content: $pi-icon-char-delete;
    }
}
