﻿/* grays */
$black: #0F151A !default;
$darkgray: #303233 !default;
$dimgray: #616466 !default;
$gray: #AFB1B3 !default;
$silver: #D4D7D9 !default;
$whitesmoke: #EDF0F2 !default;
$snow: var(--nsw-palette-grey-04) !default;
$white: #fff !default;

/* colors */
$purple: #54267e !default;
$purple-dark: mix(black, $purple, 40%) !default;
$purple-light:  mix(white, $purple, 40%) !default;

$blue: #005aad !default;
$blue-dark: mix(black, $blue, 40%) !default;
$blue-light: mix(white, $blue, 40%) !default;
$blue-lighter: mix(white, $blue, 90%) !default;

$lightblue: #00abe6 !default;
$lightblue-dark: mix(black, $lightblue, 10%) !default;
$lightblue-light: mix(white, $lightblue, 40%) !default;
$lightblue-lighter: mix(white, $lightblue, 90%) !default;

$purplered: #8c0080;
$purplered-dark: mix(black, $purplered, 40%) !default;
$purplered-light: mix(white, $purplered, 40%) !default;

$red: #dc0451 !default;
$red-dark: mix(black, $red, 40%) !default;
$red-light: mix(white, $red, 40%) !default;
$red-lighter: mix(white, $red, 90%) !default;

$green: #009581 !default;
$green-dark: mix(black, $green, 40%) !default;
$green-light: mix(white, $green, 40%) !default;
$green-lighter: mix(white, $green, 90%) !default;

$lightgreen: #66cc00 !default;
$lightgreen-light: mix(white, $lightgreen, 40%) !default;
$lightgreen-lighter: mix(white, $lightgreen, 90%) !default;

$orange: #f58220 !default;
$orange-dark: mix(black, $orange, 40%) !default;
$orange-light: mix(white, $orange, 40%) !default;
$orange-lighter: mix(white, $orange, 90%) !default;