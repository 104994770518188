﻿.skip-to {
    a:active,
    a:focus {
        position: absolute;
        top: 10px;
        left: 10px;
        padding: 0.4em 0.8em;
    }

    @include mobile {
        display: none;
    }

    @include tablet {
        display: none;
    }
}

.nav-helix {
    width: 16px;
    height: 18px;
    display: inline-block;
    margin: 0 2px 0px 0;
    background-repeat: no-repeat;

    &.black {
        background-image: url('/content/pinfo/images/icons/black-helix.png');
    }

    &.blue {
        background-image: url('/content/pinfo/images/icons/blue-helix.png');
        margin: 0px !important;
    }

    &.white {
        background-image: url('/content/pinfo/images/icons/white-helix.png');
    }
}



#topnav {
    .user-menu {
        display: flex;
        align-items: center;

        .dropdown-menu.show {
            top: 12px !important;
            max-width: fit-content !important;
        }

        > li {
            display: flex;
            align-items: center;
            list-style: none;
            margin: 0 3px 0 30px;

            > a {
                display: flex;
                align-items: center;
                color: var(--nsw-text-light);
                text-decoration: none;
                font-weight: normal;

                > .material-icons:first-child {
                    margin-right: 3px;
                }
            }

            > #profile-menu.dropdown-menu > a.dropdown-item {
                display: flex;
                align-items: end;
            }

            > .dropdown-menu {
                > a.dropdown-item {
                    color: var(--nsw-brand-dark);
                    text-decoration: none;

                    &:hover, :focus {
                        background-color: var(--nsw-text-hover);
                        outline: 2px solid var(--nsw-text-hover);
                    }

                    &:visited {
                        color: var(--nsw-text-visited);
                    }

                    > .material-icons {
                        margin-right: 5px;
                    }
                }
            }
        }
    }
}

.header {
    @media (min-width: 1280px) {
        .search-input {
            margin-left: 20px;
        }
    }

    @include tablet {
        background-color: $mobile-header-bg-color;
        padding: 0;


        .container {
            padding-top: 16px !important;
            padding-right: 0px !important;
        }
    }

    @include mobile {
        position: relative;

        .container {
            padding-top: 16px !important;
            padding-right: 0px !important;
            z-index: 2;
        }
    }

    a, a:hover {
        text-decoration: none;
    }

    .waratah {
        position: absolute;
        top: 0;
        box-shadow: 0px 2px 7px silver;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        padding: 16px;
    }

    .top-bar-logo {
        display: inline-block;
        max-width: 50px;
    }

    .col-ci-pi {
        padding: 53px 0 15px 0;
    }

    .arrow-up {
        width: 0;
        height: 0;
        border-left: 11px solid transparent;
        border-right: 11px solid transparent;
        border-bottom: 12px solid #000;
    }

    .btn-link {
        color: $header-link-color !important;

        &:focus, &:hover {
            text-decoration: none;
            color: $header-link-hover-color !important;
        }
    }

    .btn-default::after {
        content: none;
    }

    .container {

        &:after, &:before {
            content: " ";
            display: table;
        }

        .row {
            margin-left: 0px !important;

            &:first-child {
                margin-bottom: 7px;
            }

            &:after, &:before {
                content: " ";
                display: table;
            }
        }
    }

    #logo-parent-container {
        display: none;
    }

    .fa-user {
        @include desktop {
            margin-right: 8px;
        }
    }

    .fa-angle-down:before {
        font-size: 1.33333333em;
        line-height: 0.75em;
        vertical-align: -15%;
        display: inline-block;
        -webkit-transition: -webkit-transform 0.1s;
        transition: -webkit-transform 0.1s;
        transition: transform 0.1s;
        transition: transform 0.1s,-webkit-transform 0.1s;
    }

    .dropdown-menu {
        margin: 0.725rem 0 0 -1.9988rem;
        min-width: 11.95rem;
        padding: .8rem 0;
        font-size: 1rem;
        color: #212529;
        text-align: left;
        list-style: none;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid rgba(0,0,0,0.15);
        border-radius: .25rem;
        box-shadow: none;
        -webkit-box-shadow: none;

        &::before {
            top: -12px;
            left: 16px;
            border-left: 11px solid transparent;
            border-right: 11px solid transparent;
            border-bottom: 12px solid #ccc;
        }

        &::after {
            top: -11px;
            left: 17px;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 11px solid #fff;
        }
    }
    // Our Sites menu //
    .btn-group-our-sites {

        .fa-angle-down::before {
            font-size: 1.33333333em;
            line-height: 0.75em;
            vertical-align: -15%;
            display: inline-block;
            -webkit-transition: -webkit-transform 0.1s;
            transition: -webkit-transform 0.1s;
            transition: transform 0.1s;
            transition: transform 0.1s,-webkit-transform 0.1s;
        }

        .btn-link {
            white-space: inherit;
            font-weight: normal;
            font-size: $header-link-font-size;

            &:before, &:after {
                @extend .pi-icon;
                position: relative;
                top: -1.5px;
            }

            &:before {
                margin-right: 8px;
            }
        }

        .dropdown-menu {
            font-size: $font-size-smaller;

            .bold {
                font-size: $font-size-default;
                font-weight: bold;
            }

            li {
                margin: 0px;
            }

            a {
                padding-left: $padding-greater-than-half;
                padding-right: $padding-greater-than-half;
                padding-top: 4px;
                padding-bottom: 4px;

                &:hover {
                    background-color: $header-link-dropdown-hover-bg-color;
                }

                span {
                    font-weight: 700;
                    font-size: $font-size-default;
                }
            }
        }

        &.open .btn-link:after {
            -webkit-transform: rotate(0.5turn);
            -ms-transform: rotate(0.5turn);
        }
    }
    // Account options menu //
    .btn-group-account-links {

        .btn-link {
            white-space: inherit;
            font-weight: normal;
            font-size: $header-link-font-size;

            &:before, &:after {
                @extend .pi-icon;
                position: relative;
                top: -1.5px;
            }

            &:after {
                content: $pi-icon-char-angle-down;
                margin-left: 8px;
                font-size: 8px;
                float: right;
                top: 8px;
            }
        }

        .dropdown-menu {
            margin-left: $padding-half;
            font-size: $font-size-smaller;

            .bold {
                font-size: $font-size-default;
                font-weight: bold;
            }

            a {
                padding-left: $padding-greater-than-half;
                padding-right: $padding-greater-than-half;
                padding-top: 4px;
                padding-bottom: 4px;

                &:hover {
                    background-color: $header-link-dropdown-hover-bg-color;
                }
            }
        }

        &.open {
            .btn-link:after {
                -webkit-transform: rotate(0.5turn);
                -ms-transform: rotate(0.5turn);
            }
        }
    }
    // Search control //
    .search-input {
        input,
        button {
            font-size: 17px;
            border-color: #ccc;
            height: 39px !important;
        }

        input {
            @include no-appearance;
            padding: 14px;
            box-shadow: none;
            border-right-width: 0px;
            height: auto;

            @include ie {
                outline-color: transparent;
            }
        }

        button {
            border-color: #979797;
            border-left-width: 0px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            color: $purple;

            &:hover {
                color: $header-search-button-hover-color;
                background-color: transparent;
                border-color: #ccc;
            }
        }

        .form-control {
            @include placeholder-color($header-search-placeholder-color);

            &:focus {
                box-shadow: none;
                -webkit-box-shadow: none;
                border-color: #ccc !important;
                outline: 5px auto -webkit-focus-ring-color;

                + .input-group-btn button {
                    border: 1px solid #ccc !important;
                    border-left: 0px !important;
                }
            }
        }

        .input-group {
            position: relative;
            display: table;
            border-collapse: separate;
            margin: 0;
            bottom: -20px;
            width: 287px;
            float: right;

            label {
                font-weight: 600;
            }

            .form-control {
                display: table-cell;
                position: relative;
                z-index: 2;
                float: left;
                margin-bottom: 0;
                font-size: 16px;
                padding: 14px;
                border-color: #ccc;
                -webkit-appearance: none;
                box-shadow: none;
                border-right-width: 0;
                height: auto;
            }

            .input-group-btn {
                display: table-cell;
                width: 1%;
                vertical-align: middle;
                float: none;

                button {
                    background-color: #F5F9FC;
                    border: 1px solid #D4D7D9;
                    border-left: 0px;
                    border-top-right-radius: 4px !important;
                    border-bottom-right-radius: 4px !important;
                    height: auto;
                    top: 1%;

                    &:active {
                        @include no-box-shadow;
                    }
                }
            }
        }

        #txt-header-search {
            border-top-left-radius: 4px !important;
            border-bottom-left-radius: 4px !important;
        }

        .help-block {
            display: none;
        }

        .has-error {
            .form-control, button {
                border-color: #dc0451 !important;
            }

            .help-block {
                display: table-row;
                position: absolute;
                width: 175px;
                top: 47px;
                left: 7px;
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }

    .action-nav .dropdown-menu {
        top: 30px;

        @media (max-width: 459px) {
            min-width: 220px;
        }
    }

    @include mobile {
        .search-button {
            text-decoration: none;

            &:hover {
                text-decoration: none;
            }

            &:focus {
                text-decoration: none;
            }
        }

        .dropdown-menu > li > a {
            display: block;
            clear: both;
            font-weight: normal;
            line-height: 1.3;
            color: #333;
            white-space: nowrap
        }

        .ci-icon-our-sites:before {
            color: $header-link-color;
            position: relative;
            margin-right: 8px;
            top: -0.5px;
        }
    }

    @include tablet {
        .search-button {
            text-decoration: none;

            &:hover {
                text-decoration: none;
            }

            &:focus {
                text-decoration: none;
            }
        }

        .dropdown-menu > li > a {
            display: block;
            clear: both;
            font-weight: normal;
            line-height: 1.3;
            color: #333;
            white-space: nowrap
        }

        .ci-icon-our-sites:before {
            color: $header-link-color;
            position: relative;
            margin-right: 8px;
            top: -0.5px;
        }
    }
}

.btn-patient-info {
    float: right;
    margin-top: -5rem;

    .fa-angle-right:before {
        content: "\f105";
    }
}

@media (min-width: 62rem) {

    .nsw-header__container .nsw-header__search-area {
        width: 31.625rem;

        .nsw-form__input-group {
            margin-right: 150px;
        }
    }
}

#btn-sm-patient-info {
    position: absolute;
    bottom: 0;
    box-sizing: border-box;
    height: 35px;
    width: 125px;
    right: 10px;
    line-height: 35px;
    border: 1px solid #005AAD;
    border-bottom: none;
    border-radius: 5px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    color: #005AAD;
    font-size: 14px;
    font-weight: 700;

    @include desktop {
        display: none !important;
    }

    .fa-angle-right {
        margin-left: 8px !important;

        &:before {
            content: "\f105";
        }
    }
}

.fa, .fab, .fas {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}

input:focus {
    outline: none !important;
}

button:focus {
    outline: 0 !important;
}

.nav-bar-button {
    color: $pi-color-primary-4;

    &.nav-bar-my-saved-pages-button {
        display: none !important;
    }

    &.navbar-toggle:before {
        @media (max-width: 360px) {
            font-size: 15px !important;
        }
    }

    @media (max-width: 360px) {
        font-size: 15px !important;
    }
}

.navbar .navbar-collapse .navbar-nav > li.active > a {
    border-bottom: none !important; //ugh
}

.navbar .navbar-collapse .navwrap .navbar-nav .nav-item.mega-menu {
    margin-top: 0px;
}
