﻿@import "utils/mixins";

body, html {
    height: 100%;


    @include mobile {
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
    }
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $placeholder-color !important;
}

::-moz-placeholder { /* Firefox 19+ */
    color: $placeholder-color !important;
}

:-ms-input-placeholder { /* IE 10+ */
    color: $placeholder-color !important;
}

:-moz-placeholder { /* Firefox 18- */
    color: $placeholder-color !important;
}

/* Allow only vertical resizing of textareas. */
textarea {
    resize: vertical;
}



// underline line ignore list
.extra-info-block,
.useful-contacts,
.summary-cards-section
.my-information-container,
.tabs li,

.simple-page.sitemap ul li,
.glossary-listing-container .index-container ul li,
.oursites li {
    a, a:hover, a:focus {
        text-decoration: none;
    }
}

a.card {
    text-decoration: none;
}

main {
    min-height: 600px;
    position: relative;

    @include mobile {
        min-height: inherit;
        overflow-x: hidden; // We use this to hide main nav on mobile and the two big circles.
    }

}

a:not([href]) {
    cursor: pointer;
}

/*classes to toggle screen reader text depending on collapse state*/
.sr-when-collapsed, .sr-when-expanded {
    @extend .sr-only;
}

.collapsed {
    .sr-when-collapsed {
        display: block;
    }

    .sr-when-expanded {
        display: none;
    }
}

.sr-when-collapsed {
    display: none;
}

img {
    max-width: 100%;
}