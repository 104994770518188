﻿// flex version - remove once IE9 and IE10 no longer required
$card-border-color: $silver;


.cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .card {
        border: 1px solid $card-border-color;
        border-radius: $spacer/2;
        background-color: $white;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
        max-width: 100%;
        text-align: center;
        margin-bottom: $spacer*2;

        @include desktop {
            margin-bottom: $spacer*4;
        }

        .card-body {
            padding: $spacer*2;

            @include desktop {
                padding: $spacer*3 $spacer*4;
            }

            *:first-child {
                margin-top: 0;
            }

            *:last-child {
                margin-bottom: 0;
            }

            i[class*="sprite"] + h3 {
                margin-top: $spacer*2;
            }
        }

        &.card-gray {
            background-color: $snow;
        }
    }

    a.card {
        color: $black;
        text-decoration: none;

        h2, h3, h4 {
            color: $blue;
            margin-bottom: $spacer*2;
            
            line-height: 1.25 !important;
        }

        i[class*="sprite"] + h3 {
            color: $black;
        }

        &:hover, &:focus {
            border: 1px solid $blue-dark;

            h2, h3, h4 {
                color: $blue-dark;
            }

            i[class*="sprite"] + h3 {
                color: $blue-dark;
            }
        }
    }

    &.cards-left {

        .card {
            text-align: left;
        }
    }

    &.cards-image {

        .card {
            background-color: $snow;

            img {
                border-top-left-radius: $spacer/2;
                border-top-right-radius: $spacer/2;
                flex: 0 0 auto;
                max-width: 100%;
                max-height: 100%;
            }
        }

        a.card {

            h3 {
                color: $black;
                font-size: 18px !important;
                line-height: 1.25 !important;
            }

            &:hover, &:focus {
                h3 {
                    color: $blue-dark;
                }
            }
        }
    }

    &.cards3 {
        margin-left: -$spacer*2;
        margin-right: -$spacer*2;

        .card {
            flex-grow: 0;
            flex-shrink: 1;
            flex-basis: calc(50% - 16px);
            max-width: calc(50% - 16px);
            margin-left: $spacer;
            margin-right: $spacer;

            @include desktop {
                flex-grow: 0;
                flex-shrink: 1;
                flex-basis: calc(33.33% - 32px);
                max-width: calc(33.33% - 32px);
                margin-left: $spacer*2;
                margin-right: $spacer*2;
            }
        }
    }

    &.cards4 {
        margin-left: -$spacer*2;
        margin-right: -$spacer*2;

        .card {
            flex-grow: 0;
            flex-shrink: 1;
            flex-basis: calc(50% - 16px);
            max-width: calc(50% - 16px);
            margin-left: $spacer;
            margin-right: $spacer;

            @include desktop {
                flex-grow: 0;
                flex-shrink: 1;
                flex-basis: calc(25% - 32px);
                max-width: calc(25% - 32px);
                margin-left: $spacer*2;
                margin-right: $spacer*2;
            }
        }
    }

    &.cards-expandable {

        .card {
            text-align: left;
            margin-bottom: $spacer;

            @include desktop {
                margin-bottom: $spacer*2;
            }

            .card-body {
                padding: $spacer*2;

                > a {
                    font-size: $font-size-large;
                    color: $black;
                    text-decoration: none;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .caret {
                        color: $primary;
                    }

                    + .collapse, + .collapsing {
                        margin-top: $spacer*2;
                    }

                    &:not(.collapsed) {
                        font-weight: bold;
                    }
                }
            }
        }
    }
}

.well {
	padding: $spacer*3;
	background-color: $snow;
	border-color: $silver;
}

.panel-default {

	background-color: $snow;
	border-color: $silver;

	.panel-heading {
		background-color: $snow;
		padding: $spacer*2;
		border-bottom: none;

		>*:first-child {
			color: $blue-dark;
			margin: 0;
		}
	}

	.panel-body {
		padding: $spacer*2;

		.list-lined {
			li {
				padding: $spacer*1.5 0;
			}
		}

		>*:first-child {
			margin-top: 0;
		}
		>*:last-child {
			margin-bottom: 0;
		}
	}

	.panel-footer {
		background-color: $snow;
		padding: $spacer*2;
		border-top: none;
	}

	.panel-heading+.panel-body, .panel-body+.panel-footer {
		padding-top: 0;
	}
}