﻿.nologo {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;

    @include mobiletablet {
        margin: 0 auto;
        align-items: flex-end;
    }

    @include desktop {
        align-items: flex-end;
    }

    i[class*="sprite"] {
        margin-right: 16px;
        font-size: 11.7px;

        @include desktop {
            margin-right: 30px;
            font-size: 14px;
        }
    }

    img {
        width: 50px;
        height: 55px;
        @include mobiletablet {
            margin-right: 16px;
        }
    }

    .text {
        display: flex;
        flex-direction: column;
        font-size: 18px;
        line-height: 1;
        color: #fff;
        font-family: 'Lola Bold', sans-serif;

        @include mobiletablet {
            text-align: left;
        }

        @include desktop {
            flex-direction: row;
            font-size: 24px;
            margin-bottom: 2px;
        }
    }

    .ci {
        font-family: 'Lola Bold', sans-serif;
        margin-bottom: 2px;
        font-size: 28px;
        font-weight: 700;

        @include mobiletablet {
            font-size: 18px;
        }

        @include desktop {
            color: $purple;
        }

        @media only screen and (max-width: 350px) {
            font-size: 14px;
        }
    }

    .pi {
        font-family: 'Lola Regular', sans-serif;

        @include desktop {
            position: relative;
            margin-left: 19px;
            color: $pi-color-primary-2;

            &:before {
                content: '';
                border-left: 1.5px solid $pi-color-primary-2;
                height: 24px;
                position: absolute;
                top: 1.5px;
                left: -9px;
            }
        }
    }
}
