﻿.panel-group {
	margin-bottom: $spacer*6;

	> .panel {
		border: none;
		background-color: transparent;
		-webkit-box-shadow: none;
		box-shadow: none;

		> .panel-heading {
			padding: 0;
			background-color: var(--nsw-brand-dark);

			> .panel-title {
				font-size: 20px;
				font-weight: bold;

				> a {
					background-color: transparent;
					color: var(--nsw-text-light);
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: $spacer*1.5 0;
					text-decoration: none;
					outline: none;

					&:hover {
						background-color: transparent;
						color: var(--nsw-text-dark);

						.caret {
							color: var(--nsw-text-dark);
						}
					}

					.caret {
						color: var(--nsw-text-light);
					}

					&.collapsed {
						//background-color: var(--nsw-palette-grey-04);
						//color: var(--nsw-text-dark);

						.caret {
							transform: rotate(-90deg);
						}
					}
				}
			}
		}

		> .panel-collapse .panel-body {
			padding: $spacer*1.5 0 $spacer*3;
			border-top: none;
		}

		.list-group-item {
			padding: 0;
			border: none;
			border-top: 1px solid $whitesmoke;

			a {
				display: block;
				padding: $spacer $spacer $spacer $spacer*3;
			}
		}

		.panel + .panel {
			margin-top: 0;
		}
	}

	&.panel-inverse {

		> .panel {

			> .panel-heading {
				border-bottom: none;

				> .panel-title {

					> a {
						background-color: $primary;
						color: $white;
						padding-left: $spacer*2;
						padding-right: $spacer*2;
						border-radius: $spacer/2;

						.caret {
							color: $white;
						}
					}
				}
			}
		}
	}
}
