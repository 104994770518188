@import 'utils/_mixins';
@import '_variables';

.btn {
    font-size: $font-size-large;
    padding: $spacer*1.25 $spacer*2;
    text-decoration: none;

    &:focus, &.focus, &:active:focus, &:active.focus, &.active:focus, &.active.focus {
        outline: none;
        outline-offset: 0;
        box-shadow: 0 0 5px $primary-light;
        text-decoration: none;
    }

    .caret {
        margin-left: $spacer/2;
    }

    &.btn-default {
        color: $pi-color-gray-1;

        &:hover, &:focus {
            background-color: $purple-light;
        }
    }

    &.btn-primary {
        background-color: var(--nsw-brand-dark);

        &:hover, &:focus {
            background-image: linear-gradient(rgba(var(--nsw-white-rgb),.15),rgba(var(--nsw-white-rgb),.15));
        }
    }

    &.btn-secondary {
        background-color: var(--nsw-brand-dark);


        &:hover, &:focus {
            background-image: linear-gradient(rgba(var(--nsw-white-rgb),.15),rgba(var(--nsw-white-rgb),.15));
        }
    }

    &.btn-link {
        &:hover, &:focus {
            text-decoration: none;
        }
    }

    &.btn-danger {
        color: $red;
        background-color: $white;
        border-color: $red;

        &:hover, &:focus {
            color: $white;
            border-color: $red;
            background-color: $red;
        }
    }
}

.btn-lg {
	padding: $spacer*1.5 $spacer*2;
}

.btn-combo {
	width: 100%;

	.dropdown-toggle {
		color: $black;
		text-align: left;
		border: none;
		position: relative;
		z-index: 2;
		border-radius: 4px;

		&:hover, &:focus {
			color: $black;
			background-color: $white !important;
		}

		.caret {
			float: right;
		}

		.fa, .caret {
			color: $blue;
		}

	}

	.dropdown-menu {
		width: 100%;
		padding: 0;
		margin: 0;
		border: none;
	}

	&.open {

		.dropdown-toggle {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			-webkit-box-shadow: none;
			box-shadow: none;
		}

		.dropdown-menu {
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}

	}

}