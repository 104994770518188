﻿.big-chevron-right {
    display: none;
    padding: 16px;

    a {
        &:hover {
            text-decoration: none;
        }
    }
}

.big-chevron-text {
    @include mobile {
        display: none;
    }
}
