// overwrite style guide _navs.scss

#mainMenu {

    &.navbar-inverse {
        .navwrap {
            @include desktop {
                min-height: 0 !important;
                position: relative !important;
            }
        }

        .navbar-collapse {
            z-index: 2;

            a, button {
                @include mobile {
                    visibility: hidden;
                }
            }

            &.open {
                a, button {
                    @include mobile {
                        visibility: visible;
                    }
                }
            }
        }

        .navbar-nav, .dropdown-menu {
            > li {
                &.active > a, &.open > a {
                    border-bottom-width: 0;
                    color: $almost-black;
                    background-color: var(--nsw-text-hover);
                    font-weight: 700;

                    @include desktop {
                        color: $white;
                        background-color: $primary;
                        border-bottom-width: inherit;
                        font-weight: inherit;
                    }

                    &:hover, &:focus {
                        background-color: var(--nsw-text-hover);
                        border-bottom-color: var(--nsw-text-hover);

                        @include desktop {
                            background-color: var(--nsw-text-hover);
                            border-bottom-color: var(--nsw-text-hover);
                        }
                    }
                }
                // overwrite desktop version above
                &.open > a {
                    @include desktop {
                        background-color: var(--nsw-text-hover);
                    }
                }
            }
        }
        // .navbar-nav overwrite
        .navbar-nav {
            > li {
                &:first-child > {
                    @include mobile {

                    }
                }

                &.active {
                    > a {
                        @include desktop {
                            background-color: var(--nsw-text-hover);
                        }
                    }

                    &.open {
                        > a {
                            @include desktop {
                                border-bottom-color: var(--nsw-text-hover);
                            }
                        }
                    }
                }
            }
        }
        // .dropdown-menu overwrite
        .dropdown-menu {
            > li {
                &.active > a, &.open > a {
                    @include desktop {
                        background-color: var(--nsw-text-hover);
                    }

                    &:hover, &:focus {
                        @include desktop {
                            background-color: $primary;
                        }
                    }
                }
            }
        }
    }

    .oursites {
        .dropup {
            &.isSmalliphone {
                margin-top: -$spacer*0.5 !important;
            }

            .dropdown-menu {

                @include mobile {
                    width: 100%;
                    padding: 0;
                    margin: 0;
                    border: none;
                    border-bottom: 1px solid #D0D2D3;
                }

                li {
                    a {
                        font-size: 14px;

                        span {
                            font-size: 16px;
                            font-weight: 700;
                        }
                    }
                }
            }

            button {
                .ci-icon-our-sites {

                    @include mobile {
                        font-weight: 400;
                        color: #4c4c4c;
                    }

                    &:before {
                        color: $purple;
                        position: relative;
                        margin-right: 8px;
                        top: -.5px;
                    }
                }
            }
        }

        .dropdown-toggle {
            &.btn-default {

                @include mobile {
                    text-align: left;
                    border: none;
                    position: relative;
                    z-index: 2;
                    padding: 12px;
                }

                &:focus, &:hover {
                    @include mobile {
                        color: $pi-color-gray-1;
                        background-color: $white;
                    }
                }
            }
        }

        .open {
            button {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }

            .btn-default.dropdown-toggle {
                color: $pi-color-gray-1;
            }

            .caret {

                @include mobile {
                    -webkit-transform: rotate(0);
                    transform: rotate(0);
                }
            }
        }

        .caret {
            @include mobile {
                margin-top: 3px;
                float: right;
                -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
            }
        }
    }
}
