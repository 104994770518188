﻿// this is executed before the bootstrap imports.
// you can override any bootstrap SASS variables

$font-size-base: 16px;
$font-size-h1: $font-size-h1;
$font-size-h3: $font-size-h3;

$brand-primary: $blue;

$dropdown-link-hover-bg: #e6e6e6 !default;

$text-color: $almost-black;

// input
$input-color: $almost-black;
$input-border: #979797;

$btn-font-size: 18px;
$btn-default-color: $blue;
$btn-default-border: $blue;
$btn-primary-bg: $blue;

$padding-base-vertical: 14px;
$padding-base-horizontal: 16px;
$navbar-padding-vertical: 16px;

$form-group-margin-bottom: 24px;

$state-danger-text: $red;

$messagebox-font-size-mobile: 14px;

$navbar-margin-bottom: 0px;
$navbar-inverse-bg: $dark-blue;
$navbar-inverse-color: $font-inverse-color;
$navbar-inverse-link-color: $font-inverse-color;
$navbar-inverse-brand-color: $font-inverse-color;
$navbar-border-radius: 0px;

$dropdown-link-active-bg: $submenu-border-bottom-color;

$link-hover-color: $hover-color;
$link-hover-decoration: none;

$pagination-color: #555;

$line-height-base: 1.5;

$grid-float-breakpoint: 992px;

$breadcrumb-active-color: $dimgray;