﻿.tabs-sticky .panel {
    @include mobile {
        border-width: 0px;
    }
}

.extra-info-block {
    margin-top: 0px;
    margin-bottom: $spacer * 3;
    background-color: var(--nsw-brand-light);
    padding: ($spacer * 3) ($spacer * 2) ($spacer * 2) ($spacer * 2);
    border: none;
    border-top: 6px solid var(--nsw-brand-dark);
   

    &:last-child {
        margin-bottom: $spacer * 5;
    }

    @include tablet {
        padding: ($spacer * 3) ($spacer * 3) ($spacer * 2) ($spacer * 3);
    }

    @include desktop {
       
        padding: ($spacer * 3) ($spacer * 3) ($spacer * 2) ($spacer * 3);
       
        border-top: 6px solid var(--nsw-brand-dark);
  
    }

    .extra-info-block-description {
        margin-bottom: $spacer * 2;

        + .extra-info-row {
            &:before {
                content: inherit;
                display: inherit;
                margin: inherit;
                width: inherit;
                padding: inherit;
                border-bottom: none;
                margin-bottom: inherit;
                margin-top: inherit;

                @include mobile {
                    width: inherit;
                }
            }
        }
    }

    .extra-info-row:first-of-type {
        &:before {
            content: inherit;
            display: inherit;
            margin: inherit;
            width: inherit;
            padding: inherit;
            border-bottom: none;
            margin-bottom: inherit;
            margin-top: inherit;

            @include mobile {
                width: inherit;
            }
        }
    }

    .extra-info-title {
        font-weight: 700;
    }
}

.extra-info-block-title {
    font-weight: bold;
    line-height: 26px;
    margin-bottom: 16px;
    margin-top: 0px;

    span {
        margin-right: $spacer;
    }
}

.checklists {
    .extra-info-title {
        display: table-cell;
        vertical-align: middle;
    }
}

.recommended-links {

    .sensitive-data-icon, .disclaimer-icon {
        position: relative;
        top: -2px;

        &:before {
            font-size: 13px;
            margin-left: 2px;
        }
    }

    .sensitive-data-icon {
        @extend .pi-icon-sensitive-data;
    }

    .disclaimer-icon {
        @extend .pi-icon-disclaimer;
        top: -2px;

        &:before {
            margin-left: 5px;
            color: var(--nsw-text-dark)
        }
    }

    .disclaimer {
        .popover {
            .popover-content {
                color: #333;
            }
        }
    }

    .popover {
        border: 1px solid #CCCCCC;
        border-radius: 4px;
        box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);

        .popover-content {
            font-size: 14px;
            color: #353535;
            
        }
    }

    .extra-info-row:last-of-type {
        &:before {
            margin-top: 33px;
        }
    }
}

.recommended-links-meta-info {
    
    font-size: $font-size-smaller;
    line-height: 21px;
}

.extra-info-row {
    @extend .row;
}

.extra-info-row:before {
    content: "";
    display: block;
    margin: 0 auto;
    width: 92%;
    padding-top: 0px;
    border-bottom: 2px solid var(--nsw-text-dark);
    margin-bottom: 12px;
    margin-top: 12px;

    @include mobile-small {
        width: 92%;
    }

    @include tablet {
        width: 96%;
    }
}

.extra-info-bookmark {
    @extend .col-sm-3;
    @extend .col-xs-3;
    display: table;
}

.extra-info-bookmark-vertical-center {
    display: table-cell;
    vertical-align: middle;
    text-align: right;
}

.extra-info-title-container {
    @extend .col-sm-9;
    @extend .col-xs-9;
    display: table;
}

.extra-info-title {
    line-height: 24px;
    cursor: pointer;
}

@include tablet {
    .checklists {
        float: left;
        width: 100%;
    }

    .recommended-links {
        float: left;
        width: 100%;
    }
}