﻿.detail-page-body {
    font-size: $font-size-medium;
    line-height: 1.5;
    margin-bottom: $spacer*5;
    padding-top: $spacer*3;

    @include desktop {
        padding-top: 0px;
    }
}

.detailpage-index {

    & + .related-pages {
        border-top: 1px solid $pi-color-gray-6;
    }

    .detail-page-body-section {
        background-color: #f9fafb;

        .important-information {
            background-color: #fff;
        }

        & + .detail-page-tab-content-section {
            background-color: $pi-color-primary-4;
        }
    }

    .detail-page-tab-content-section {
        &.has-tabs {
            @media only screen and (max-width: #{$tablet-width - 1px}) {
                display: none;
            }
        }
    }

    .detail-page-tab-section {
        @include desktop {


            > .container {
                margin-bottom: -1px;

                .sticky-wrapper {
                    margin-bottom: -1px;
                }

                .tabs {


                    li {
                        border-bottom: 0px;
                        border-radius: 2px;

                        &.active {
                            color: var(--nsw-text-light);


                            a {
                                background-color: var(--nsw-brand-dark);
                                color: var(--nsw-text-light);
                            }

                            i {
                                color: var(--nsw-text-light);
                            }
                        }

                        &.last-tab {
                            a {
                                margin-right: inherit;
                            }
                        }

                        a {
                            background-color: #fff;
                            padding: ($spacer * 3) 5px;
                            margin-right: $spacer;
                            border: 1px solid $tab-border-color;


                            i[class*="sprite"] {
                                margin-bottom: -$spacer;
                            }
                        }
                    }
                }
            }
        }
    }

    .detail-page-right-panel {
        margin-top: $spacer * 3;
        margin-bottom: $spacer * 3;

        @include tablet {
            float: left;
            background-color: initial;
            width: 100%;
            margin-bottom: 0px;
            margin-top: $spacer * 2;
        }

        @include desktop {
            margin-top: 0px;
            margin-left: 0px;
            padding-left: 6.2%;
        }
    }

    div[id$=-accordion] {
        margin-left: -17px;
        margin-right: -17px;

        .content-block {
            margin-left: 15px;
            margin-right: 15px;
            margin-top: 15px;
        }

        .panel {
            margin-top: 2px;
            border: 0px;
        }

        .panel-heading {
            font-size: 20px;
            padding: $spacer*1.5;

            a {
                padding: 0px !important;

                .tab-title {
                    width: 100%;
                }

                &:hover {
                    text-decoration: none;
                }
            }
        }

        .active {
            background-color: var(--nsw-text-hover);

            h2, * {
                color: var(--nsw-text-dark);
            }
        }

        margin-bottom: 0px;
    }

    .sticky-title {
        &.affix {
            div[class^=col-] {
                width: 100%;
            }
        }
    }

    .page-header {
        margin-bottom: 0px;
        margin: 0px;

        @include mobile {
            height: inherit;
        }

        @include tablet {
            padding-bottom: 0px;
        }

        @include desktop {
            .bookmark-button {
                margin-top: 0px;
            }
        }

        h1 {
            margin: 0px;
        }
    }

    .arrow-up {
        display: none;
    }

    .open {
        .arrow-up {
            display: block;
        }
    }

    @media (max-width: 991px) {
        .section-intro {


            .section-tabs {
                padding: $spacer*4 0px;


                .extra-info-block:last-child {
                    margin-bottom: 0px;
                }
            }
        }

        .detail-page-right-panel {
            margin: $spacer*4 0px 15px 0px;
        }
    }
}

.detail-page-section {
    &.no-title-section {
        margin-top: 8px;
    }

    @include mobile {
        margin-bottom: 0px;
        padding-bottom: 0px;
    }
}
.section-tabs {
    border-bottom: 2px solid var(--nsw-brand-dark);
    position: relative;
    top: 49px;
}
.content-blocks {
    .content-block:last-child {
        *:last-child {
            margin-bottom: 0px;
        }
    }
}