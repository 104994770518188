﻿[id ^= "checklistModal-"] {

    .modal-content {
        padding: $card-margin-default;
        border-radius: $border-radius * 2;
        border-color: #CCCCCC;
        box-shadow: none;

        @include mobile {
            padding: $card-margin-half;
        }
    }

    .modal-header, .modal-body, .modal-footer {
        padding: 0px;
    }

    .modal-header {
        border-bottom: 2px solid #E6E6E6;
        padding-bottom: 20px;
        flex-flow: wrap;
    }

    .modal-footer {
        border: none;
    }

    .modal-title {
        color: inherit;
        font-family: "Lola Bold", serif;
        font-size: 28px !important;
        line-height: 28px !important;
        margin-right: 5px;

        @include mobile {
            margin-right: 0px;
        }
    }

    .modal-abstract {
        margin: 23px 0px 17px 0px;
        font-size: 18px;
        line-height: 27px;
        display: inline-block;
        width: 100%;
        margin-bottom: 0px;
    }
    // ul comes from content authors
    .modal-body > ul {
        margin: 23px 0px 45px 0px;
        padding-left: 33px;

        > li {
            margin-bottom: 14px;
        }

        @include mobile {
            margin: 19px 0px 32px 0px;
            padding-left: 14px;
        }
    }

    .btn {
        border-color: $blue;
        border-radius: $border-radius * 2;
        line-height: normal;
        padding: $padding-half $padding-half + 3 $padding-half $padding-half + 3;

        + .btn {
            margin-left: $margin-default;
        }

        &:before {
            margin-right: $margin-half;
        }

        &:hover, &:focus {
            border-color: $hover-color;
        }

        @include mobile {
            display: block;
            width: 100%;

            + .btn {
                margin-left: 0px;
                margin-top: $card-margin-half;
            }
        }
    }

    .nsw-button.print {
        &:before {
            @extend .material-icon;
            content: "\e8ad";
            font-size: 18px;
            margin-right: 10px;
        }
    }

    .btn.save {



        &:before {
            @extend .pi-icon;
            content: "\e902";
        }

        &:hover, &:focus {
        }
    }

    .bookmark-button {
        font-size: 24px;
        line-height: 31px;
    }

    .modal-title-wrapper {
        display: flex;
        flex: 0 0 100%;
        @include flex-flow(row);
        @include justify-content(space-between);
        margin-top: $margin-default;

        @include desktop {
            margin-top: 0px;
        }

        .close {
            width: auto;
            margin-left: $spacer*3;
            margin-top: -1rem;
            @include align-self(baseline);
        }
    }
}

@media print {
    .checklist-modal {
        padding: 0px;
        position: static;

        .modal-dialog, .modal-content, .modal-header, .modal-body {
            position: static;
        }

        .modal-dialog {
            margin: 0px;
            width: auto;
        }

        .modal-content {
            border: none;
            padding: 40px 23px 40px 40px;
        }

        button, .modal-footer {
            display: none !important;
        }

        .logo {
            height: 60px;
        }

        .print-header {
            margin-bottom: 35px !important;

            h1 {
                display: block !important;
                float: right !important;
                margin: 0px !important;
                line-height: normal !important;
                padding-top: 13px !important;
            }
        }

        .modal-header {
            border: none;
        }

        .modal-title, .modal-abstract, modal-body {
            color: $almost-black !important;
        }

        .modal-title {
            font-size: 27px !important;
        }

        .modal-abstract {
            margin-top: 26px;
            font-size: 14px;
            line-height: 1.5
        }

        .modal-body {
            font-size: 14px;
            font-weight: bold;
            line-height: normal;

            ul {
                list-style: none;
                padding-left: 0px;

                li {
                    page-break-inside: avoid;
                    text-indent: -36.5px;
                    margin-left: 36.5px;
                }

                li:before {
                    content: " ";
                    display: inline-block;
                    height: 20px;
                    width: 20px;
                    border: 1px solid $almost-black;
                    margin-right: 16.5px;
                    margin-bottom: -4.5px;
                }

                li:after {
                    content: " ";
                    display: block;
                    margin: 22.5px 0px;
                    height: 22.5px;
                    border-top: 1.5px solid #999999;
                    border-bottom: 1.5px solid #999999;
                }
            }
        }
    }
}