﻿$grays: (
	"black": $black,
	"darkgray": $darkgray,
	"dimgray": $dimgray,
	"gray": $gray,
	"silver": $silver,
	"whitesmoke": $whitesmoke,
	"snow": $snow,
	"white": $white,
	"transparent": transparent
);

@each $color, $hex in $grays {
  .#{$color} {
    color: $hex !important;
  }
  .bg-#{$color} {
    background-color: $hex;
  }
  .border-#{$color} {
    border: 1px solid $hex;
  }
  .border-top-#{$color} {
    border-top: 1px solid $hex;
  }
  .border-bottom-#{$color} {
    border-bottom: 1px solid $hex;
  }
}

$colors: (
	"purple": $purple,
	"purple-dark": var(--nsw-brand-dark),
	"purple-light": $purple-light,
	"blue": $blue,
	"blue-dark": $blue-dark,
	"blue-light": $blue-light,
	"blue-lighter": $blue-lighter,
	"lightblue": $lightblue,
	"purplered": $purplered,
	"purplered-dark": $purplered-dark,
	"purplered-light": $purplered-light,
	"red": $red,
	"red-lighter": $red-lighter,
	"green": $green,
	"green-lighter": $green-lighter,
	"lightgreen": $lightgreen,
	"orange": $orange,
	"orange-lighter": $orange-lighter,
);

@each $color, $hex in $colors {
  .#{$color} {
    color: $hex;
  }
  .bg-#{$color} {
    background-color: $hex;
  }
  .border-#{$color} {
    border-color: $hex;
  }
  .btn-#{$color} {
    background-color: $hex;
    border-color: $hex;
  }
}

.swatch {
	height: 100px;
	width: 100px;
	border-radius: 8px;
	margin-bottom: 8px;
}