﻿.section {
    padding: $spacer*4 0;

    @include desktop {
        padding: $spacer*6 0;
    }

    &.slim {
        padding: $spacer*2 0;

        @include desktop {
            padding: $spacer*3 0;
        }        
    }

    > .container {
        > *:first-child, [class*="col-"] > *:first-child {
            margin-top: 0;
        }

        > *:last-child, [class*="col-"] > *:last-child {
            margin-bottom: 0;
        }
    }
}
