
$primary: $purple !default;
$primary-light: $purple-light !default;
$primary-dark: $purple-dark !default;

$font-size-default: 16px !default;
$font-size-large: 18px !default;
$font-size-larger: 20px !default;
$font-size-small: 14px !default;
$font-size-smaller: 12px !default;

$card-border-color: $silver !default;

$menu-border-color: $primary-light !default;

$spacer: 8px;

$mobile-width: 768px;
$tablet-width: 992px;

//screen sizes
$small-mobile-width: 640px;
$mobile-width: 768px;
$tablet-width: 992px;
$spacer: 8px;
