﻿.simple-page {
    .page-body {
        line-height: 24px;

        h2, h3, h4, h5 {
            margin-bottom: 20px;
        }

       
    }

    &.sitemap {
        ul {
            &.list-sidebar {
                list-style: none;
                padding-left: $spacer * 3;
         

                li {
                    a {
                       
                        text-decoration: none;

                        &:hover, &:focus {
                            
                        
                        }
                    }

                    ul {
                        list-style: none;
                        padding-left: $spacer * 3;
                        margin-top: $spacer;
                        margin-bottom: $spacer * 3;
                    }
                }
            }
        }
    }
}