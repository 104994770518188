﻿.title-wrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    @include desktop{
        h1 {
            max-width: 75%;
        }
    }

    .bookmark-button {
        font-weight: bold;
        margin-top: $spacer;
        &:after {
            font-size: $spacer*2;
        }
    }

    @include desktop {
        flex-direction: row;

        .bookmark-button {
            margin-left: $spacer*2;
        }
    }
}