﻿// out sites desktop
.btn-group-our-sites {

	.btn-link {
		white-space: inherit;
		font-weight: normal;
		font-size: $font-size-default;

		@include mobile {
			font-size: $font-size-btn;
		}

		&:focus, &:hover {
			text-decoration: none;
		}

		&:before, &:after {
			@extend .pi-icon;
			position: relative;
			top: -1.5px;
		}

		&:before {
			margin-right: 8px;
		}
	}

	.dropdown-menu {
		margin-left: $padding-half;
		font-size: $font-size-smaller;
        left: 0 !important;
        margin: 0 !important;

		.bold {
			font-size: $font-size-base;
			font-weight: bold;
		}

		li {
			margin: 0px;
		}

		a {
			padding-left: $padding-greater-than-half;
			padding-right: $padding-greater-than-half;
			padding-top: 4px;
			padding-bottom: 4px;
		}
	}
}

.btn-group-our-sites.open .btn-link:after {
	-webkit-transform: rotate(0.5turn);
	-ms-transform: rotate(0.5turn);
}

// out sites mobile
.btn-group-our-sites.visible-xs-inline-block {
    background-color: $body-bg;
    width: 100%;
    margin-top: $padding-medium * 2.5;
    margin-bottom: $padding-medium * 2.5;
    border-radius: $border-radius-base;

    .btn-link {
        color: $almost-black;
        width: 100%;
        text-align: left;
        padding-top: 10px;
        padding-bottom: 10px;

        &:before, &:after {
            color: #005aad;
        }

        &:after {
            -webkit-transform: rotate(0.5turn);
            -ms-transform: rotate(0.5turn);
        }
    }

    .dropdown-menu {
        left: 0px;
        right: 0px;
        padding: 0px;
        margin: 0px;
        background-color: $body-bg;
        border: none;
        border-bottom: 1px solid #cccccc;
        box-shadow: none;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;

        a {
            color: $almost-black;
            padding-left: $padding-greater-than-half;

            &:focus, &:hover {
                background-color: $dropdown-link-hover-bg;
            }
        }
    }
}

.btn-group-our-sites.visible-xs-inline-block.open .btn-link:after {
	-webkit-transform: inherit;
	-ms-transform: inherit;
}

.btn-group-our-sites.visible-xs-inline-block.open {
	z-index: 2;
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;	
}

.sm-collapsible + .btn-group-our-sites.visible-xs-inline-block.open + .overlay {
	display: block;
}
