@import 'utils/_mixins';
@import '_variables';

@mixin placeholder {
    &::-webkit-input-placeholder {@content}
    &:-moz-placeholder           {@content}
    &::-moz-placeholder          {@content}
    &:-ms-input-placeholder      {@content}
  }
  
  form {
      margin-bottom: $spacer*4;
  
      fieldset {
          margin-bottom: $spacer*3;
  
      &:last-of-type {
        margin-bottom: 0;
      }
      }
  
      legend {
          font-size: $font-size-small;
          
          padding-bottom: $spacer/2;
      }
  
      label {
          font-size: $font-size-default;
      }
  
      .form-group {
          margin-bottom: $spacer*3;
  
          .checkbox, .radio {
              font-weight: normal;
          }
  
          &.required label::after {
              content:' *';
          }
  
          .help-block {
              margin-top: $spacer*1.5;
          }
  
          .form-control {
              -webkit-box-shadow: none;
              box-shadow: none;
  
              &:focus {
                  border-color: $blue;
                box-shadow: 0 0 3px $blue-light;
              }
          }
  
          .form-control {
              height: $spacer*6;
              padding: $spacer*1.5 $spacer*2;
              font-size: $font-size-default;
              border-color: $gray;
  
              @include placeholder {
                  color: $dimgray;
              }
          }
  
          input[type="file"]:focus, input[type="radio"]:focus, input[type="checkbox"]:focus {
            outline: none;
            outline-offset: 0;
            box-shadow: 0 0 3px $blue;
          }
  
          &.has-error {
  
              .form-control {
                  border-color: $red;
                  -webkit-box-shadow: none;
                  box-shadow: none;
  
                  &:focus {
                    box-shadow: 0 0 2px $red;
                  }
              }
  
              .help-block, .control-label, .radio, .checkbox, .radio-inline, .checkbox-inline {
                  color: $red;
              }
          }
      }
  }