﻿.section-cta {
    background-color: var(--nsw-brand-dark);
    ;
    color: var(--nsw-text-light);
    font-size: 20px;

    a, a:link {
        text-decoration: none;
        white-space: nowrap;
        color: var(--nsw-text-light);
    }

    > .container {
        @include flexbox;
        @include flex-direction(column);
        @include justify-content(center);

        @include desktop {
            @include flex-direction(row);
        }

        .cta-message {
            margin-bottom: 24px;

            @include desktop {
                margin-bottom: 0;
                text-align: left;
                @include flex-direction(row);
            }

            .h1 {
                color: var(--nsw-text-light);
            }
        }
    }

    > .container > div {
        @include flexbox;
        @include flex-direction(column);
        @include justify-content(center);
        @include align-items(center);
        text-align: center;
        @include flex(2);

        @include desktop {
            margin-left: 24px;
            margin-right: 24px;
        }

        > * {

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
