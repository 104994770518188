﻿@import "variables";

.pi-icon
{
    font-family: 'patientinfo-icon-set' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.material-icon {
    font-family: 'Material Icons';
    speak: none;
}


.pi-icon-edit-profile:before {
    @extend .pi-icon;
    content: $pi-icon-char-edit-profile;
}

.pi-icon-bookmark-solid:before {
    @extend .pi-icon;
    content: $pi-icon-char-star-filled;
}

.pi-icon-char-bookmark-hover:before {
    @extend .pi-icon;
    content: $pi-icon-char-bookmark-hover;
}

.pi-icon-tick-filled:before {
    @extend .pi-icon;
    content: $pi-icon-char-tick-filled;
}

.pi-icon-user:before {
    @extend .pi-icon;
    content: $pi-icon-char-user;
}

.pi-icon-login:before {
    @extend .pi-icon;
    content: '\e912';
}

.pi-icon-logout:before {
    @extend .pi-icon;
    content: '\e911';
}

.pi-icon-related:before {
    @extend .pi-icon;
    content: $pi-icon-char-related;
}

.pi-icon-angle-down:before {
    @extend .pi-icon;
    content: $pi-icon-char-angle-down;
}

.pi-icon-question:before {
    @extend .pi-icon;
    content: $pi-icon-char-question;
}

.pi-icon-help-phone:before {
    @extend .pi-icon;
    content: $pi-icon-char-help-phone;
}

.pi-icon-hollow-star-in-circle:before {
    @extend .pi-icon;
    content: $pi-icon-char-hollow-star-in-circle;
}

.pi-icon-close:before {
    @extend .pi-icon;
    content: $pi-icon-char-close;
}

.pi-icon-bars:before {
    @extend .pi-icon;
    content: $pi-icon-char-bars;
}

.pi-icon-hamburger:before {
    @extend .pi-icon;
    content: $pi-icon-char-hamburger;
}

.pi-icon-print:before {
    @extend .pi-icon;
    content: $pi-icon-char-print;
}

.pi-icon-document:before {
    @extend .pi-icon;
    content: $pi-icon-char-document;
}

.pi-icon-delete:before {
    @extend .pi-icon;
    content: $pi-icon-char-delete;
}

.pi-icon-bookmark:before {
    @extend .pi-icon;
    content: $pi-icon-char-bookmark;
}

.pi-icon-sensitive-data:before {
    @extend .pi-icon;
    content: $pi-icon-char-disclaimer;
}

.pi-icon-disclaimer:before {
    @extend .pi-icon;
    content: $pi-icon-char-disclaimer;
    color: $pi-color-understate;
}

.pi-icon-checklists:before {
    @extend .pi-icon;
    content: $pi-icon-char-checklists;
}

.pi-icon-resources:before {
    @extend .pi-icon;
    content: $pi-icon-char-resources;
}

.pi-icon-globe {
    margin-right: 2px !important;
}

.pi-icon-globe:before {
    content: "\f7a2";
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
}