
/* margin and padding */
$spacer-x: $spacer * 1 !default;
$spacer-y: $spacer * 1 !default;
$spacers: (
  0: ( x: 0, y: 0 ),
  1: ( x: $spacer-x, y: $spacer-y ),
  2: ( x: ($spacer-x * 2), y: ($spacer-y * 2) ),
  3: ( x: ($spacer-x * 3), y: ($spacer-y * 3) ),
  4: ( x: ($spacer-x * 4), y: ($spacer-y * 4) ),
  6: ( x: ($spacer-x * 6), y: ($spacer-y * 6) ),
  8: ( x: ($spacer-x * 8), y: ($spacer-y * 8) )
) !default;

@each $prop, $abbrev in (margin: m, padding: p) {
  @each $size, $lengths in $spacers {
    $length-x: map-get($lengths, x);
    $length-y: map-get($lengths, y);

    .#{$abbrev}#{$size}  { #{$prop}:        $length-y $length-x !important; } // a = All sides
    .#{$abbrev}t#{$size} { #{$prop}-top:    $length-y !important; }
    .#{$abbrev}r#{$size} { #{$prop}-right:  $length-x !important; }
    .#{$abbrev}b#{$size} { #{$prop}-bottom: $length-y !important; }
    .#{$abbrev}l#{$size} { #{$prop}-left:   $length-x !important; }

    // Axes
    .#{$abbrev}x#{$size} {
      #{$prop}-right:  $length-x !important;
      #{$prop}-left:   $length-x !important;
    }
    .#{$abbrev}y#{$size} {
      #{$prop}-top:    $length-y !important;
      #{$prop}-bottom: $length-y !important;
    }
  }
}