.dropdown {

    &.show, &.open {
        .icon::before {
            -webkit-transform: scaleY(-1);
            transform: scaleY(-1);
        }
    }

    .dropdown-menu {
        margin-top: 0;

        > li > a {
            padding: $spacer * .75 $spacer * 1.5;
            font-size: 14px !important;
            font-weight: 400 !important;
            line-height: 1.25 !important;
            text-decoration: none;
        }

        &::before {
            content: '';
            position: absolute;
            top: -12px;
            left: 16px;
            width: 0;
            height: 0;
            border-left: 11px solid transparent;
            border-right: 11px solid transparent;
            border-bottom: 12px solid #ccc;
        }

        &::after {
            content: '';
            position: absolute;
            top: -11px;
            left: 17px;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 11px solid #fff;
        }

        &.dropdown-menu-right::before {
            left: auto;
            right: 16px;
        }

        &.dropdown-menu-right::after {
            left: auto;
            right: 17px;
        }

        .small, small {
            font-size: 80% !important;
            font-weight: 400 !important;
        }
    }
}

.dropover-dropdown {
    z-index: 1015;
}