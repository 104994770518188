﻿/* Search popup */
body.menu-open, .account-open {
    overflow: hidden;
}

@include mobiletablet {
    body.account-open .account-body {
        transform: translateX(0);
        position: relative;
        z-index: 2;
    }

    .account-body {
        &::before {
            right: 60px !important;
        }

        &::after {
            right: 60px !important;
        }
    }

    .account-body {
        background-color: #fff;
        display: block;
        position: absolute;
        height: 100vh;
        width: 100%;
        z-index: 1;
        transform: translateX(100%);
        transition: transform .5s;

        ul > li > a {
            display: flex;
            align-items: end;

            > .material-icons {
                margin-right: 5px;
            }
        }
    }
}
