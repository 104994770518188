﻿//spacing
$spacer: 8px;

/* Responsive breakpoints */
$mobile-width: 992px;
$mobile-small-width: 767px;

// Overwite style guide


$primary: $purple;
$primary-dark: mix(black, $primary, 40%);
$primary-light: mix(white, $primary, 40%);
$primary-lighter: mix(white, $primary, 90%);

//Color pallete
$pi-color-primary-1: #CBEDFD;
$pi-color-primary-2: #002664;
$pi-color-primary-3: #146CFD;
$pi-color-primary-4: #FFFFFF;

$pi-color-gray-1: #22272B;
$pi-color-gray-2: #22272B;
$pi-color-gray-3: #495054;
$pi-color-gray-4: #495054;
$pi-color-gray-5: #495054;
$pi-color-gray-6: #495054;
$pi-color-gray-7: #CDD3D6;
$pi-color-gray-8: #EBEBEB;
$pi-color-gray-9: #EBEBEB;

$pi-colors: (
    "primary-1": $pi-color-primary-1,
    "primary-2": $pi-color-primary-2,
    "primary-3": $pi-color-primary-3,
    "primary-4": $pi-color-primary-4,
    "gray-1": $pi-color-gray-1,
    "gray-2": $pi-color-gray-2,
    "gray-3": $pi-color-gray-3,
    "gray-4": $pi-color-gray-4,
    "gray-5": $pi-color-gray-5,
    "gray-6": $pi-color-gray-6,
    "gray-7": $pi-color-gray-7,
    "gray-8": $pi-color-gray-8,
    "gray-9": $pi-color-gray-9
);

@each $pi-color, $hex in $pi-colors {
  .#{$pi-color} {
    color: $hex;
  }
  .bg-#{$pi-color} {
    background-color: $hex;
  }
  .border-#{$pi-color} {
    border-color: $hex;
  }
  .btn-#{$pi-color} {
    background-color: $hex;
    border-color: $hex;
  }
}

// table
$table-columns: 10;

/* default container size in grid (use the following variable for mobile first css)*/
// $container-lg -- 1170px
// $container-md -- 970px
// $container-sm -- 750px

$fa-font-path: "../../Content/pinfo/fonts";

$fonts-path: "../../Content/pinfo/fonts";

$even-darker-blue: #002664;
$dark-blue: #146CFD;
$light-blue-accessible-over-dark-blue: #CBEDFD;
$blue: #CBEDFD;

// less used
$brand-blue-2: #146CFD;
$active-menu-item-border-color: $brand-blue-2;

$sky-blue: #CBEDFD;
$hover-color: #CBEDFD;
$light-blue: #CBEDFD;
$greenish: #004000;
$pi-color-understate: #EBEBEB;
$pi-color-dark-disable: #495054;
$pi-color-light-disable: #CDD3D6;
$pi-color-bookmark-bookmarked: #FAAF05;
$pi-color-reccomended-links-block-title: #630019;

// fonts
$font-inverse-color: #fff;

$font-size-btn: 18px;
$font-size-default: 16px;
//$font-size-smaller: 14px; TODO: To be deleted after testing
$font-size-semi-medium: 18px;
$font-size-medium: 20px;
//$font-size-large: 28px; TODO: To be deleted after testing
$font-size-mobile-h1: 28px;
$font-size-mobile-h2-small: 20px;
$font-size-mobile-h3: 24px;
$font-size-mobile-h3-small: 18px;
$font-size-mobile-default: 16px;
$font-size-h1: 36px;
$font-size-h1-extra-small: 20px;
$font-size-h1-extra-large: 60px;
$font-size-h2-small: 24px;
$font-size-h3: 32px;
$font-size-h3-small: 22px;
$font-size-h3-extra-large: 42px;

//line heights
$pi-normal-text-line-height: 35px;
$pi-default-line-height-percent: 150%;
$pi-default-line-height: 1.5;

$border-radius: 4px;

$menu-item-light-grey-background-color: #EBEBEB;
$breadcrumb-desktop-background-color: #fff;
$breadcrumb-colour-bg: #EBEBEB;
$breadcrumb-bg : #FFF;

// padding
$padding-default: 24px;
$padding-medium: 16px;
$padding-half: 12px;
$padding-greater-than-half: $padding-half * 4 / 3;

$margin-default: 24px;
$margin-half: 12px;
$margin-medium: 16px;
$margin-greater-than-half: $margin-half * 4 / 3;
$margin-less-than-half: $margin-half * 2 / 3;

$card-margin-default: 32px;
$card-margin-half: 16px;

$card-title-font-color: #555;

$light-grey: rgba(0,0,0,0.2);

$light-blue-over-dark-blue: #336FA8;
$almost-black: #22272B;
$menu-item-border-color: #EBEBEB; // x1
$submenu-border-bottom-color: #e6e6e6;
$active-menu-item-mobile-bg-color: $submenu-border-bottom-color;

$register-form-control-height: 48px;

$placeholder-color: #666;

$purple: #441170;
$orange: #F3631B;
$red: #D7153A;

$black-shadow: rgba(0,0,0,.075);

$white-variant-20: rgba(255, 255, 255, 0.2);

$active-border-width: 5px;

$state-info-text: $blue;
$state-info-border: $brand-blue-2;
$state-info-bg: #AFEAFF;

$btn-danger-bg: $red;

// Main menu
$main-menu-active-color: #004c93;

// Landing page or detail page css
$light-blue-background-color: #EAEDF4;
$tab-border-color: $pi-color-gray-6;



//ICONS
$pi-icon-char-sensitive-data: "\e900";
$pi-icon-char-disclaimer: "\e916";
$pi-icon-char-bookmark-solid: "\e902";
$pi-icon-char-tick-filled: "\e903";
$pi-icon-char-user: "\e904";
$pi-icon-char-related: "\e905";
$pi-icon-char-angle-down: "\e906";
$pi-icon-char-question: "\e907";
$pi-icon-char-help-phone: "\e908";
$pi-icon-char-hollow-star-in-circle: "\e909";
$pi-icon-char-close: "\e90a";
$pi-icon-char-bars: "\e90b";
$pi-icon-char-hamburger: "\e90c";
$pi-icon-char-print: "\e90d";
$pi-icon-char-delete: "\e90e";
$pi-icon-char-document: "\e90f";
$pi-icon-char-bookmark: "\e917";
$pi-icon-char-edit-profile: "\e910";
$pi-icon-char-bookmark-hover: "\e918";
$pi-icon-char-important-information: "\e915";
$pi-icon-char-star-line: "\e918";
$pi-icon-char-star-filled: "\e917";
$pi-icon-char-checklists: "\e919";
$pi-icon-char-resources: "\e91a";

$main-menu-hover-bg-color: var(--nsw-text-hover);
$main-menu-bg-color: $dark-blue;
$main-menu-open-bg-color: $even-darker-blue;
$mobile-menu-wrap-background-color: $dark-blue;
$header-font-color: $pi-color-gray-2;
$mobile-menu-caret-color: $blue;
$font-size-larger: 20px;
$header-search-button-color: $blue;
$mobile-search-popup-heading-color: $pi-color-primary-4;
$mobile-search-heading-font-size: $font-size-large;
$mobile-search-popup-bg-color: $primary;
$header-background-color: $pi-color-primary-4;
$mobile-header-bg-color: $purple;
$header-link-color: $blue;
$header-link-hover-color: $dark-blue;
$header-link-font-size: $font-size-default;
$header-link-dropdown-hover-bg-color: #e6e6e6;
$header-search-button-size: $font-size-semi-medium;
$header-search-button-hover-color: $dark-blue;
$header-search-placeholder-color: $pi-color-gray-3;
