﻿$print-font-size: 16px;

@media print {
    @page {
        size: 8.26in 11.69in !important;
        margin: 10mm !important;
    }
    body, html {

        @include mobile {
            overflow-x: visible;
        }
    }

	.modal-open {
		overflow: visible;
	}

	.modal-open > * {
		display: none;
	}

	.modal-open > .modal.in {
		display: block;
	}

    .container {
        width: auto;
    }

    [class^=col-] {
        width: 100%;
    }

    main {

        &#main {
            > .container {
                padding-left: 0;
                padding-right: 0;
            }
        }

        h2, h3, h4 {
            margin-top: 16px
        }

        h2 {
            color: #005aad !important;
            padding-bottom: 30px;
            margin-top: 16px;
        }

        margin-top: unset;
        border-top: none;
    }

    dl, ol, p, pre, ul {
        margin-bottom: 10px;
    }

    .header,
    .navbar,
    .search-input,
    #topnav,
    .breadcrumb,
    .global-cancer-type-menu,
    .page-header,
    .bookmark-button,
    .btn-group-section-selector,
    .section-title,
    .related-pages,
    footer {
        display: none;
    }
    .nsw-breadcrumbs,
    .nsw-masthead,
    .nsw-skip {
        display: none !important;
    }

    #cancerselector {
        display: none !important;
    }


    a[href]:after {
        content: none !important;
    }

    .list-lined li {
        padding: 8px 0;
    }

    h1 {
        margin-top: 32px;
        margin-bottom: $margin-default;
    }

    .header-print {
        .logo {
            height: 60px;
        }
    }

    .cancertype-badge {
        display: none;
    }

    ul, li, p {
        font-size: $print-font-size;
        line-height: $print-font-size * 1.5;
    }

    .detail-page-body, .detail-page-section {
        font-size: $font-size-smaller !important;
        margin-bottom: 0 !important;
    }

    .sticky-wrapper {
        display: none;
    }

    .tab-content.multiple-tab-pane .tab-pane {
        display: block !important;

        &:first-child {
            page-break-inside: auto;
        }
    }

    .detail-page-section-title-print, .cta-message-intro .h1 {
        margin: 30px 15px 5px 15px;
        border: none;
        page-break-after: avoid;

        img {
            display: none;
        }
    }

    .detail-page-right-panel {
        margin: 0;
        padding-left: 0;
        margin-bottom: 10px;
    }

    .detail-page-section {
        opacity: 1 !important;
        margin-top: 0px !important;
        padding-bottom: 0 !important;
        transition: initial;


        &.no-title-section {
            margin-top: 40px !important;
        }

        .content-blocks {
            float: none;
            width: 100% !important;

            .content-block {
                font-size: $print-font-size;

                .content-block-title {
                    font-size: 22px !important;
                    margin-bottom: 10px;
                    page-break-after: avoid;
                }
            }
        }

        .checklists {
            display: none;
        }

        .row {
            display: block !important;
        }

        .recommended-links-meta-info {
            font-size: $print-font-size !important;
        }

        .recommended-links {
            margin-top: 20px;
            margin-bottom: 12px;
            font-size: $print-font-size;
            page-break-inside: avoid;

            .extra-info-block-title {
                font-size: 22px !important;
                font-family: inherit;
            }

            .extra-info-block-title:before {
            }

            .extra-info-row:last-of-type {
                padding-bottom: 10px;

                &:before {
                    display: none !important;
                    margin-top: 12px;
                }
            }
        }

        .extra-info-block {
            padding: .8em 1em 0 1em !important;
            background-color: var(--nsw-brand-light);
            border-right: 1px solid black;
            border-left: 1px solid black;
            border-bottom: 1px solid black;

            .extra-info-block-description {
                margin-bottom: 10px;
            }
        }

        .extra-info-block-title {
            margin-bottom: 10px;
        }

        .extra-info-row:before {
            width: 100% !important;
            margin-bottom: 5px;
            margin-top: 5px;
        }

        .extra-info-bookmark {
            display: none !important;
        }

        .extra-info-title-container {
            width: 100% !important;

            .recommended-links-title {
                font-weight: bold;
            }
        }
    }

    .useful-contacts {
        padding: 32px 0px;
        page-break-before: always;

        .container {
            border: 2px solid #cccccc !important;
            padding: $padding-half;

            .contact-info-panel {
                margin: 0 10% 0 10% !important;
                width: 80% !important;
                text-align: center;

                .contact-info {
                    h3 {
                        font-size: $font-size-h1 * 0.75;
                    }

                    .pi-icon-help-phone:before {
                        color: $blue !important;
                        font-size: 30px !important;
                    }

                    .contact-description {
                        font-size: $print-font-size !important;
                        line-height: 21px !important;
                    }
                }
            }

            .contact-section-panel {
                margin: 1% 20% 0 20% !important;
                width: 60% !important;

                .contact-section {
                    .row {
                        .contact-item {
                            font-size: $print-font-size !important;
                            padding: 6px 0 6px 0;
                        }
                    }
                }
            }
        }
    }

    .section-cta {
        & > .container {
            display: block !important;


            .cta-message, .cta-multilinks {
                display: block !important;
                font-size: $print-font-size;
                margin: 0;
                
            }

            .cta-message-intro {
                text-align: left !important;
                font-size: $print-font-size;

                .h1 {
                    margin-left: 0px;
                    margin-bottom: 10px;
                }
            }

            a, a:link {
                color: #000 !important;
                font-size: $print-font-size;
            }
        }
    }

    .cta-links {
        margin: 0;
        display: block !important;
        text-align: left !important;

        p {
            display: inline-block;
            margin-right: 20px;
            font-size: 13px;
        }
    }

    .my-notes {
        display: none !important;
        page-break-inside: avoid;

        h4 {
            color: $almost-black;
            font-family: "Lola Bold", serif;
            font-weight: bold;
            font-size: 18px;
            line-height: 23px;
            margin-bottom: 12px;
            margin-top: 0px;
        }

        .my-notes-row {
            border-bottom: 1px solid #cccccc;
        }
    }

    .glossary-term {
        cursor: inherit !important;
        border-bottom: 0px !important;
    }
    .breadcrumb-nav {
        display: none;
    }

    .section-intro h1 {
        margin-bottom: 20px;
        margin-Top: 40px
    }

    .section {
        padding: 0px;

        &.section-intro {
            border-bottom: none;
            border-top: none !important;
            padding-bottom: 10px;

            .background-circles-container {
                display: none;
            }
        }
    }
    .examples, .important-information {
        &:before {
            display: none;
        }
        padding-left: $spacer*2;
        padding: 1em 1em 1em 1em;
        margin-top: 15px;
        line-height: 1.5;
    }
}
