﻿@mixin media-min($width) {
    @media (min-width: #{$width}) {
        @content;
    }
}

@mixin media-max($width) {
    @media (max-width: #{$width}) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: #{$mobile-width - 1px}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$tablet-width}) {
        @content;
    }
}

@mixin mobile-small {
    @include media-max($mobile-small-width) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: #{$mobile-width}) and (max-width: #{$tablet-width - 1px}) {
        @content;
    }
}

@mixin mobiletablet {
    @media (max-width: #{$tablet-width - 1px}) {
        @content;
    }
}

@mixin full-screen {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

@mixin no-appearance {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

@mixin transform-rotate($degree) {
    -webkit-transform: rotate($degree);
    -moz-transform: rotate($degree);
    -ms-transform: rotate($degree);
    -o-transform: rotate($degree);
    transform: rotate($degree);
}

@mixin background-circles {
    .background-circle1, .background-circle2 {
        position: absolute;
        border-radius: 50%;
        border-style: solid;
        z-index: -1;
    }

    .background-circle1 {
        height: 534px;
        width: 541px;
        opacity: 0.1;
        border: 25px solid var(--nsw-brand-dark);
        right: -354px;
        bottom: 38px;
    }

    .background-circle2 {
        border: 100px solid var(--nsw-brand-dark);
        height: 702px;
        width: 712px;
        opacity: 0.1;
        right: -201px;
        bottom: -422px;
    }
}

.background-circles-container {
    background-size: cover;
    position: absolute;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -2;
    @include background-circles;
}

@import "../sprites/_sprites.generated";

@mixin full-width {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

@mixin top-triangle($background-color, $border-color, $right) {
    .dropdown-menu:after,
    .dropdown-menu:before {
        content: '';
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
    }
    /* this border color controls the color of the triangle (what looks like the fill of the triangle) */
    .dropdown-menu:after {
        top: -20px;
        right: $right;
        border-color: transparent transparent $background-color transparent;
        border-width: 10px;
    }
    /* this border color controls the outside, thin border */
    .dropdown-menu:before {
        top: -22px;
        right: $right - 1;
        border-color: transparent transparent $border-color transparent;
        border-width: 11px;
    }
}

@mixin box-shadow($box-shadow) {
    -moz-box-shadow: $box-shadow;
    -webkit-box-shadow: $box-shadow;
    box-shadow: $box-shadow;
}

@mixin no-padding($side) {
    @if $side == 'all' {
        .no-padding {
            padding: 0 !important;
        }
    }
    @else {
        .no-padding-#{$side} {
            padding-#{$side}: 0 !important;
        }
    }
}

@include no-padding("left");
@include no-padding("right");
@include no-padding("top");
@include no-padding("bottom");
@include no-padding("all");

@mixin box-sizing($border-box) {
    -moz-box-sizing: $border-box;
    -webkit-box-sizing: $border-box;
    box-sizing: $border-box;
}

@mixin ie {
    @media screen and (min-width:0\0) {
        @content;
    }
}

@mixin ff {
    @-moz-document url-prefix() {
        @content;
    }
}

@mixin flex($flex) {
    -ms-flex: $flex;
    -webkit-flex: $flex;
    flex: $flex;
}

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content
    }

    &:-moz-placeholder {
        @content
    }

    &::-moz-placeholder {
        @content
    }

    &:-ms-input-placeholder {
        @content
    }
}

@mixin placeholder-color($color) {
    &::-webkit-input-placeholder {
        color: $color !important;
    }

    &::-moz-placeholder {
        color: $color !important;
    }

    &:-ms-input-placeholder {
        color: $color !important;
    }

    &:-moz-placeholder {
        color: $color !important;
    }
}

@mixin no-box-shadow() {
    @include box-shadow('none !important');
}

@mixin box-shadow($box-shadow) {
    -moz-box-shadow: $box-shadow;
    -webkit-box-shadow: $box-shadow;
    box-shadow: $box-shadow;
}

@mixin hoverfocus {
    &:hover, &:focus {
        @content;
    }
}

@mixin smallmobile {
    @media (max-width: #{$small-mobile-width - 1px}) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: #{$mobile-width - 1px}) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: #{$mobile-width}) and (max-width: #{$tablet-width - 1px}) {
        @content;
    }
}

@mixin tabletdesktop {
    @media (min-width: #{$mobile-width - 1px}) {
        @content;
    }
}

@mixin mobiletablet {
    @media (max-width: #{$tablet-width - 1px}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$tablet-width}) {
        @content;
    }
}

@mixin hoverfocus {
    &:hover, &:focus {
        @content;
    }
}

@mixin removechildmargin {
    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}
