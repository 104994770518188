@import 'utils/_mixins';
@import '_variables';

$table-columns: 10;
$table-border-color: $silver;

table, .table {
  width: 100%;
  max-width: 100%;
  margin-top: $spacer*3;
  margin-bottom: $spacer*3;

	>thead>tr>th {
		border-top: 1px solid $table-border-color;
		border-bottom-width: 1px;
	}

	>thead, >tbody, >tfoot {
		>tr>th, >tr>td {
			line-height: 1.5;
			padding: $spacer*1.5 $spacer*2;
			border-top: 1px solid $table-border-color;
		}
	}

	>caption+thead>tr:first-child>th,
	>caption+thead>tr:first-child>td,
	>colgroup+thead>tr:first-child>th,
	>colgroup+thead>tr:first-child>td,
	>thead:first-child>tr:first-child>th,
	>thead:first-child>tr:first-child>td {
		border-top: 1px solid $table-border-color;
	}

	&.table-striped>tbody>tr:nth-of-type(odd) {
		background-color: $whitesmoke;
	}

  @for $i from 2 through $table-columns {
    &.col#{$i} th, &.col#{$i} td { width: (100% / $i); };
  };

  &.table-reset {

		border: 0;

		>thead, >thead:first-child, >tbody, >tfoot {
			>tr>th, >tr>td, >tr:first-child>th, >tr:first-child>td {
				line-height: 1.5;
				padding: 0;
				border: 0;
			}
		}
  }
}