﻿.jspContainer {
    overflow: hidden;
    position: relative;
}

.jspPane {
    position: absolute;
}

.jspVerticalBar {
    position: absolute;
    top: 0;
    right: 0;
    width: 6px;
    height: 100%;
    background: #d8d8d8;
}

.jspHorizontalBar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 6px;
    background: #d8d8d8;
}

.jspCap {
    display: none;
}

.jspHorizontalBar .jspCap {
    float: left;
}

.jspTrack {
    background: #d8d8d8;
    position: relative;
}

.jspDrag {
    background: #858585;
    position: relative;
    top: 0;
    left: 0;
    cursor: pointer;
}

.jspHorizontalBar .jspTrack,
.jspHorizontalBar .jspDrag {
    float: left;
    height: 100%;
}

.jspArrow {
    background: #50506d;
    text-indent: -20000px;
    display: block;
    cursor: pointer;
    padding: 0;
    margin: 0;
}

.jspArrow.jspDisabled {
    cursor: default;
    background: #80808d;
}

.jspVerticalBar .jspArrow {
    height: 16px;
}

.jspHorizontalBar .jspArrow {
    width: 6px;
    float: left;
    height: 100%;
}

.jspVerticalBar .jspArrow:focus {
    outline: none;
}

.jspCorner {
    background: #eeeef4;
    float: left;
    height: 100%;
}
