﻿// section selector desktop
.btn-group-section-selector {
    bottom: -30px;
    float: right;

    @include mobile-small {
        float: none;
        bottom: -52px;
        width: 100%;
        display: block;
    }

    @include tablet {
        float: none;
        width: auto;
    }

    &.open {
        .btn-link:after {
            @include transform-rotate(0.5turn);

            @include mobile {
                top: 0px;
                left: 3px;
            }
        }
    }

    .btn-link {
        white-space: inherit;
        background-color: $blue;
        border-radius: $border-radius-base;
        color: $body-bg;
        font-size: $font-size-smaller;
        font-weight: 500;
        line-height: 18px;
        padding: 11px 12px 11px 12px;

        @include mobile-small {
            border-radius: 50%;
            width: 31px;
            position: absolute;
            right: 0px;
        }

        &:focus, &:hover {
            text-decoration: none;

            @include desktop {
                background-color: $even-darker-blue;
            }
        }

        &:after {
            @extend .pi-icon;
            position: relative;
            content: "\e906";
            margin-left: 32px;
            font-size: 7px;
            float: right;
            top: 7px;

            @include mobile {
                margin-left: 0;
                top: 1px;
                left: 4px;
            }

            @include tablet {
                margin-left: 32px;
                padding-top: 4px;
            }
        }
    }

    @include mobile {
        @include top-triangle($blue, #fff, 20px);
    }

    .dropdown-menu {
        top: 54px;
        margin-right: -$padding-half;
        font-size: $font-size-smaller;
        box-shadow: none;
        padding-top: 14px;
        padding-bottom: 14px;

        .bold {
            font-weight: bold;
        }

        li {
            margin: 0px;
        }

        h3 {
            margin: 0;
            font-family: 'Roboto', sans-serif;
            font-size: 20px;
            font-weight: bold;
            padding: $padding-greater-than-half;
            color: $body-bg;
        }

        .line {
            height: 2px;
            border: 1px solid $body-bg;
            opacity: 0.15;
            margin: 0px $margin-greater-than-half 0px $margin-greater-than-half;
        }

        a {
            padding: 12px 90px 10px $padding-default;
            font-size: $font-size-default;
            line-height: 19px;

            @include mobile-small {
                padding: 12px 16px;
                color: $body-bg;
                font-size: $font-size-semi-medium;

                &.bold {
                    background-color: $dark-blue;

                    &:hover, &:focus {
                        background-color: $dark-blue;
                    }
                }

                &:hover, &:focus {
                    background-color: inherit;
                    color: inherit;
                }
            }
        }

        @include mobile-small {
            margin-bottom: 120px;
            width: 100vw;
            margin-left: -15px;
            position: relative;
            border-radius: 0;
            border-width: 0px;
            background-color: $blue;
            color: #fff;
        }
    }
}

