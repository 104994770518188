﻿.logon-panel-password-retrieval {

    @include desktop {
        margin-left: auto;
        margin-right: auto;
        width: 540px;
        margin-bottom: 332px;
    }

    margin-bottom: 290px;

    h1 {
        margin-top: 60px;
        margin-bottom: 40px;
        @include mobile {
            margin-top: $margin-default;
            margin-bottom: $margin-default;
        }
    }

    .password-retrieval-description {
        margin-bottom: 24px;
    }

    label {
        margin-bottom: 8px;
    }

    .btn-home {
        margin-bottom: $margin-default;
    }
}
