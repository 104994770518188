﻿.form-group .form-control {    
    height: $register-form-control-height;
}

.mini-link {
    text-align: center;
    margin: 24px 0;

    @include mobile {
        margin-top: -6px;
    }

}

.validation-summary-errors {
    ul {
        padding: 0;
        list-style-type: none;
    }
}

.form-group + .validation-summary-errors {
    margin-top: -$padding-half;
}
.form-control {
    &:focus{
        @include box-shadow(none);
    }
}
.input-validation-error {
    border-color: $red;

    &:focus {
        border-color: $red;
        @include box-shadow(none);
    }
}

.field-validation-error {
    margin-top: 12px;
    display: inline-block;
    
    @include mobile {
        margin-top: 8px;
    }
}

.btn-submit {
    height: $register-form-control-height;
    width: 100%;
    padding: 0;
    border-radius: 4px;
}

a.btn-submit{
    padding: 11px 16px;
}

input[type=checkbox] + label, input[type=checkbox] + input[type=hidden] + label {
    font-weight: normal;
}

.checkbox-vaping {
    margin: -8px 0 0 -40px;
}

.location-vaping {
    padding-top: 10px;
    display: none;
}
