// variable and colors 
@import "_variables";
@import "style_guide/_color_functions";
@import "patient_info/_pi_color_functions";



/*@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,400;0,700;1,400&amp;display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');*/


// utils
@import "utils/_mixins";
@import "utils/_responsive-utilities";
@import "utils/flex-mixins";

@import "sprites/_sprites.generated";

// bootstrap
@import "bootstrap/_bootstrap-variable-overrides";
@import "bootstrap/_bootstrap-imports";
@import "bootstrap/_bootstrap-style-overrides";

// fonts
@import "fonts/_font-awesome-imports";
@import "fonts/_fonts";

@import "../../../../node_modules/@fortawesome/fontawesome-free/scss/regular";
@import "../../../../node_modules/@fortawesome/fontawesome-free/scss/solid";
@import "../../../../node_modules/@fortawesome/fontawesome-free/scss/brands";

// libs
@import url("../../../../_lib/smartmenus/dist/css/sm-core-css.css");
@import url("../../../../_lib/smartmenus/dist/addons/bootstrap/jquery.smartmenus.bootstrap.css");

// SMACSS
@import "_base";
@import "_layout";

//icons
@import "_icons";

//bizform 
@import "_bizform";

//animate
@import url("../../../../_lib/animate.css/animate.css");

// site style guide typography
//@import 'style_guide/_typography';

// site style guide
@import 'style_guide/_accordions';
@import 'style_guide/_alerts';
@import 'style_guide/_breadcrumbs';
@import 'style_guide/_buttons';
@import 'style_guide/_cards';
@import 'style_guide/_dropdowns';
//@import 'style_guide/_footer';
@import 'style_guide/_forms';
@import 'style_guide/_lists';
@import 'style_guide/_navs';
@import 'style_guide/_spacing';
@import 'style_guide/_tables';
@import 'style_guide/_grid';

// overwrite style guide 
@import 'modules/master/_main-menu';

// modules
@import "modules/_search-input";
@import "modules/_big-chevron";
@import "modules/_extra-info";
@import "modules/_bookmark-button";
@import "modules/_form";
@import "modules/_our-sites";
@import "modules/_tab-collapse";
@import "modules/_signin-register";
@import "modules/_useful-contacts";
@import "modules/_sticky-headers";
@import "modules/_section-selector";
@import "modules/landing-page/_landing-page";
@import "modules/_summary-cards-section";
@import "modules/_error-page";
@import "modules/_simple-page";
@import "modules/_checklist-modal";
@import "modules/_my-information";
@import "modules/_search-page";
@import "modules/_password-retrieval";
@import "modules/_glossary_listing";
@import "modules/_account-edit";
@import "modules/home-page/home-page";
@import "modules/_nav-tiles";
@import "modules/_special-contents";
@import "modules/_notification-message";
@import "modules/_page-title.scss";
@import "modules/detail-page/_detail-page-body";
@import "modules/search-popup";
@import "modules/main-header";
@import "modules/nologo";
//@import "modules/footer";
@import "modules/js-panel";
@import "modules/section/section";
@import "modules/section/section-intro";
@import "modules/section/section-aboriginal";

// print
@import "print/_print";

.contact .phone-number {
    float: right;
    font-weight: bold;
    color: dodgerblue;
}

.glossary-term {
    cursor: pointer;
    border-bottom: 1px dotted var(--nsw-brand-dark);
}
a.glossary-term {
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}

.skip-to a:active,
.skip-to a:focus {
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 0.4em 0.8em;
}

.header {
    .container .row:first-child {
        margin-bottom: 7px;
    } 
}

.pi-logo {
    font: normal 28px/1 'Lola Bold', serif;
    display: inline-block;
    max-width: 200px;
}

.cinsw-logo a:hover,
.cinsw-logo a:focus,
.pi-logo a:hover,
.pi-logo a:focus {
    text-decoration: none
}

.cinsw-logo {
    display: inline-block;
    padding-top: 5px;

    .logo {
        height: 72px;
        margin-left: -3px;
    }

    svg {
        height: 60px;
        margin-left: -16px;
    }
}
.cancer-institute-logo {
    .logo {
        max-height: 100px;
        max-width: 300px;
        margin-left: 0px;
        height: inherit;
    }
}
.sprite-nsw-govt-cinsw-logo {
    display: inline-block;
    width: 122px;
    height: 56px;
    background-position: -5px 7px;
    background-repeat: no-repeat;
}

.content-block-first .content-block-title {
    margin-top: 10px;
}
.content-block-title{
    font-size: 24px;
    margin-bottom: 20px;
}
.arrow-up {
    width: 0;
    height: 0;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 12px solid black;
}

.title-sticky-wrapper {
    margin-bottom: $spacer*3;
}

.page-header {
    border: none;
    h1 {
        margin-bottom: 30px;

        @include mobile {
            margin: 0;
            bottom: 0;
            width: 90%;
            line-height: 30px;
        }

        @include tablet {
            position: static;
            margin-top: 24px;
            width: 75%;
        }
    }

    padding-bottom: 0px;

    @include mobile {
        position: relative;
        height: 84px;
        margin: 16px 0;
    }

    @include tablet {
        height: 96px;
        padding-bottom: 29px;
    }
}

#topnav a:before {
	margin-right: 8px;
}

.overlay {
	display: none;
	background-color: rgba(0,0,0,0.5);
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: 1;
}
.header {
    .search-input {
        margin-top: 35px;
        padding-left: 10px;
    }
}

.navbar-toggler:focus {
    color: var(--nsw-brand-dark);
}

.aboriginal-bg:before {
    background-image: url(/content/pinfo/images/aboriginal-artwork-bg.jpg);
    background-repeat: no-repeat;
    background-position: bottom right;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: -200px;
    z-index: -1;
    display: block;
}
.background-image-container {
    position: absolute;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
}

.footer-logo {
    width: 100%;
}
// responsive images
img {
    max-width: 100%;
}

.img-circle {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    height: auto;
    width: 100%;
    padding-bottom: calc(100% - 16px); //calc doesn't support variables
    border: $spacer solid var(--nsw-brand-dark);
    border-radius: 50%;
    position: relative;
    top: 50%;
}

.badge {
    border-radius: 3px;
}

.cancertype-badge {
    background-color: var(--nsw-palette-grey-01);
    font-size: 14px;
    font-weight: normal;
    padding: $spacer/2 $spacer;
    margin-bottom: $spacer;
}
@media (min-width: 62rem) {
    .nsw-breadcrumbs {
        margin-bottom: 1.5rem;
    }
    .mobile-show {
        display: none;
    }
   
 }

@media (max-width: 62rem) {
    .js-close-search {
        height: 72px;
    }

    .nsw-header__search-area {
        display: none;
        button[type=submit] {
            display: none;
        }
    }
    .search-open .nsw-header__search-area {
        display: block;
    }
    .nsw-header__waratah {
        width: 100%;
    }
    .desktop-show {
        display: none;
    }
}

    .quicklinks {
        margin-top: 20px;
    }


    @media (max-width: 48rem) {

        .quick-links.pr-0 a {
            margin-right: 1rem;
        }

        .quick-links.pl-0 a {
            margin-left: 1rem;
        }

        .quick-links.pl-0 li:first-child {
            border-top: none;
        }

        .footer-logo {
            .nsw-header__waratah-gov {
                padding: 0;
                justify-content: left;
                display: block;
            }

            .nsw-header__title {
                font-size: 1rem;
                justify-content: center;
            }
        }

        .quick-links {
            width: 100%;
        }

        .nsw-footer__heading {
            margin-top: 20px;
        }

        .desktop-show, .nsw-masthead .sites-explore a.dropdown-toggle div {
            display: none;
        }

        .nsw-masthead {
            a.btn-link, a.btn-link:visited, a.dropdown-toggle {
                padding: 0;

                &::before {
                    margin-right: 4px;
                }
            }
        }
    }



    @import "node_modules/nsw-design-system/src/main";
    @import "node_modules/nsw-design-system/src/components/all";

    @media (min-width: 48rem) {
        @media (max-width: 74rem) {
            .nsw-main-nav li a .material-icons {
                display: none;
            }

            .nsw-main-nav .nsw-main-nav__sub-nav li a .material-icons {
                display: block;
            }
        }

        @media (max-width: 66rem) {
            .nsw-main-nav li a {
                padding-left: 0.5rem;
                padding-right: 0.5rem;
            }
        }
    }

    a.social-icons {
        text-decoration: none;

        &:hover {
            text-decoration: none;
            background-color: transparent;
        }
    }

    .fab {
        font-family: 'Font Awesome 5 Brands';
        font-weight: 400;
        color: var(--nsw-brand-dark) !important;
        text-decoration: none;
    }

    .close {
        opacity: 1 !important;
    }

    @media (min-width: 62rem) {
        .nsw-header__waratah .hide {
            display: block !important;
        }

        .nsw-header__search-area {
            bottom: 12px;




            input {
                border: 1px solid var(--nsw-grey-01) !important;
                border-right: none !important;
                background-color: var(--nsw-white);
                height: auto;

                @media (max-width: 1200px) {
                    width: 212px;
                }
            }

            button {
                height: 48px;
                position: relative;
            }

            .input-group {
                border: 1px solid var(--nsw-grey-01) !important;
                background-color: var(--nsw-white);
                color: var(--nsw-text-dark);
                border-radius: var(--nsw-border-radius);
            }
        }
    }

    .bg-snow .nsw-link-list {

        li {
            border-top: 1px solid var(--nsw-brand-dark);

            &:last-child {
                border-bottom: 1px solid var(--nsw-brand-dark);
            }
        }
    }

    a:hover, a:focus {
        color: var(--nsw-brand-dark);
    }

/*.popover-form {
    display: none;
    position: fixed;
    z-index: 999;
    transition: all .3s ease;
    padding: 1.5rem 0;

    .left {
        top: 50%;
        left: 0;
        transform: translateY(-50%) translateX(-120%);
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left-width: 0;
    }

    .in.left {
        transform: translateY(-50%)
    }
}*/

.popover-form {
    z-index: 1015;
}

#custom-popup {
    background-color: var(--nsw-palette-purple-01);
    padding: 0.8rem 0;
    border: 1px solid var(--nsw-palette-purple-01);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#custom-alert {
    background-color: var(--nsw-palette-purple-01);
    padding: 1% 3% 0% 8%;
    text-align:left;
}

.opacity-active {
    background-color: rgba(0,0,0, 0.7); /* Black w/opacity/see-through */
    color: white;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: 1020 !important;
}

#custom-alert.opacity-remove {
    background-color: rgba(0,0,0, 0);
}

.prevent-display:hover {
    background-color: var(--nsw-palette-purple-01) !important;
}

.specific-page-style-breast-cancer-screening {
    hr {
        color: var(--nsw-palette-pink-02);
    }


    .navbar {
        background-color: var(--nsw-palette-purple-01);

        .navbar-collapse .navwrap .navbar-nav .nav-item {
            .nav-link {
                color: var(--nsw-text-light);
            }

            .nav-link:focus, .nav-link:hover {
                background-color: var(--nsw-palette-pink-02);
            }
        }
    }
}

    .specific-page-style-preventing-skin-cancer {
        hr {
            color: var(--nsw-palette-orange-02);
        }

    }

    .sticky-title h1 {
        margin-top: 0px;
    }

    .background-circles-container {
        background-color: var(--nsw-palette-grey-04);

        div {
            display: none;
        }
    }

    .blockquote-footer:before {
        content: "- "
    }

    .blockquote-footer {
        @extend cite;
    }

    .icon-right {
        float: right;
    }

    .nsw-tabs__list .material-icons {
        font-size: 18px;
    }

    .content-block {
        margin-top: 15px;
    }

    .nsw-tabs__list li.active a:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0;
        border-bottom: solid 2px var(--nsw-brand-accent);
    }

    .account-mysavedpages .tab-content {
        border-top: 2px solid var(--nsw-grey-04);
    }

    @media (min-width: 62rem) {
        .lp-lv-three.col-lg-8.col-sm-12 {

            .container {
                max-width: 100%;
            }

            .nsw-grid {
                .nsw-col-lg-3 {
                    width: 50%;
                    max-width: 50%;
                }
            }
        }
    }

    @media (min-width: 768px) {
        .container {
            width: 100%; //due to new design system styles for header / banner - override bootstrap
        }
    }

    @media (min-width: 992px) {
        .container {
            width: 970px;
        }
    }


    .px-2 {
        margin-right: 0;
        margin-left: 0;
        padding-left: 0 !important; //uggh.
        padding-right: 0 !important; //uggh.
    }

    .container {
        max-width: 100%;
        width: 100%;
    }

    @media (min-width: 1200px) {
        .container {
            width: 1170px;
            padding-left: 0;
            padding-right: 0;
        }
    }

.lp-lv-three.col-lg-8.col-sm-12 {
    padding-left: 1rem;
    padding-right: 1rem;

    .extra-info-row .row {
        margin-left: 0;
        margin-right: 0;
    }

    .col-sm-12 {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.reading-time-section {
    padding: $spacer*4 0 0;

    @include desktop {
        padding: $spacer*6 0 0;
    }

    &.slim {
        padding: $spacer*2 0 0;

        @include desktop {
            padding: $spacer*3 0 0;
        }
    }

    > .container {
        > *:first-child, [class*="col-"] > *:first-child {
            margin-top: 0;
        }

        > *:last-child, [class*="col-"] > *:last-child {
            margin-bottom: 0;
        }
    }
}

.reading-time {
    display: block;
    padding-right: 10px;
    font-size: .875em;
    color: #606060;
}

.reading-time-label {
    font-weight: 400;
    margin-top: 16px;
    margin-bottom: 16px
}