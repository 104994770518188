﻿$my-infomation-nothing-saved-message-background-color: #F6F6F6;
$tab-title-bottom-margin: 25px;
.account-mysavedpages{
    @include background-circles;

    .introduction {
        margin: 2px 0px 60px 0px;
        line-height: $pi-normal-text-line-height;
        font-size: $font-size-medium;

        @include mobile-small {
            margin-bottom: 32px;
            margin-top: 40px;
            font-size: 16px;
        }

        @include tablet {
            font-size: 18px;
            margin-bottom: 24px;
        }
    }

    main {
        padding-bottom: 0px;
    }

    .tabs {
        margin-bottom: 28px;
    }

    .bookmarked-items {
        @include mobile {
            margin-bottom: 0px;
        }

        .bookmarked-item-row {
            border-bottom: 1px solid #E6E6E6;
            padding: 12px 0px;
            line-height: 24px;
            margin-bottom: 0px;
            margin-left: 15px;
            margin-right: 15px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            @include desktop {
                .bookmarked-item-title {
                    font-size: $font-size-semi-medium;
                }

                .bookmarked-item-abstract {
                    font-size: $font-size-default;
                }
            }

            .bookmarked-item-abstract {
                @include mobile {
                    font-size: $font-size-smaller;
                }

                color: $pi-color-understate;
            }
        }
    }

    .tab-title:before {
        @extend .pi-icon;
        color: var(--nsw-brand-dark);
        font-size: $font-size-large;
        margin-right: 16px;
        vertical-align: middle;
    }

    .page-header {
        border: none;

        @include mobile {
            height: inherit;
            margin-top: 40px;
        }

        @include tablet {
            padding-bottom: 8px;
        }
    }

    .active .tab-title:before {
        color: $blue;
    }

    [href="#my-information-savedPages"] .tab-title:before {
        content: $pi-icon-char-document;
    }

    [href="#my-information-savedLinks"] .tab-title:before {
        content: $pi-icon-char-hollow-star-in-circle;
    }

    [href="#my-information-savedChecklists"] .tab-title:before {
        content: $pi-icon-char-tick-filled;
    }

    .bookmarked-items li:last-child {
        border-bottom: none;
    }

    .bookmark-button-delete:before {
        font-size: $font-size-btn;
        color: var(--nsw-brand-dark);

        &:hover, &:focus {
            color: $blue;
        }

        margin-right: 0px;
    }

    .tab-title {
        margin-top: 0px;
        margin-bottom: 0px;
        
        font-size: 20px;
        line-height: 18px;
        font-weight: 500;
    }

    .panel {
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .js-tabcollapse-panel-body {
        margin-bottom: 5px;
    }

    div[id$=-accordion] {
        .panel-heading {
            background-color: $dark-blue;
            color: $font-inverse-color;
            font-size: 20px;
            padding: $spacer*1.5;

            a {
                background-color: $dark-blue !important;
                padding: 0px !important;

                .tab-title {
                    width: 100%;
                }

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

.my-information-container {
    position: relative;

    @include desktop {
        min-height: 600px;
    }


    .section-intro {
        background-color: #fff;
    }
}

.my-information-nothing-saved-message-container {
    text-align: center;
    background-color: $my-infomation-nothing-saved-message-background-color;
    padding: 130px 0;
    border: 1px solid #E6E6E6;
    border-radius: $border-radius;

    @include desktop {
        margin-top: 17px;
        margin-bottom: 238px;
    }

    @include mobile {
        padding: 94px 0;
        margin-bottom: 11px;
    }
}

.my-infomation-nothing-saved-message {
    font-size: $font-size-medium;
    color: var(--nsw-text-dark);
    line-height: $pi-normal-text-line-height;

    .pi-icon-bookmark {
        color: var(--nsw-text-dark);
    }

    @include mobile {
        padding: 0 38px;
        margin-top: -12px;

        p {
            margin: 0px;
        }
    }
}
